import React from 'react'
import PublicIcon from '@mui/icons-material/Public';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ModalUserDetails = ({details}) => {

    const { t } = useTranslation();

  return (
    <div className='flex flex-col w-full h-full gap-y-3 overflow-y-auto bar-element-user'>
      <div className='flex flex-row gap-2 items-center'>
        <div className='small-square' />
        <div className='flex flex-row gap-1'>
            <p className='mb-0 font-semibold text-neutral-200'>{t("userModule.name")}:</p>
            <p className='mb-0 text-neutral-200'>{details.firstname} {details.lastname}</p>
        </div>
      </div>
      <div className='flex flex-row gap-2 items-center'>
        <div className='small-square' />
        <div className='flex flex-row gap-1'>
            <p className='mb-0 font-semibold text-neutral-200'>{t("userModule.email")}:</p>
            <p className='mb-0 text-neutral-200'>{details.email}</p>
        </div>
      </div>
      <div className='flex flex-row gap-2 items-center'>
        <div className='small-square' />
        <div className='flex flex-row gap-1'>
            <p className='mb-0 font-semibold text-neutral-200'>{t("userModule.role")}:</p>
            <p className='mb-0 text-neutral-200 capitalize'>{(details.role).replace('_', ' ')}</p>
        </div>
      </div>
      <div className='flex flex-row gap-2'>
        <div className='flex flex-col gap-1'>
            <div className='flex flex-row gap-2 items-center'>
                <div className='small-square' />
                <p className='mb-0 font-semibold text-neutral-200'>{t("userModule.countries_plants")}:</p>
            </div>
            {details.countries_with_plants.some((item) => item.zone === "MAZ") ? (
                <>
                    <div className='mt-2 ms-4 flex flex-row gap-1 items-center'>
                        <RadioButtonCheckedIcon className='!h-4 !w-4' />
                        <p className='mb-0 text-neutral-200 capitalize font-medium'>{t("userModule.maz_zone")}:</p>
                    </div>
                    {details.countries_with_plants.filter((item) => item.zone === "MAZ").map((item, index) => (
                        <div key={index+1} className='ms-8 flex flex-col gap-1'>
                            <div className='flex flex-row gap-1 items-center'>
                                <PublicIcon className='!h-4 !w-4' />
                                <p className='mb-0 text-neutral-200 capitalize font-medium'>{item.country}:</p>
                                <div className='flex flex-wrap gap-1'>
                                    {item.plants.map((plant, index) =>(
                                        <p key={plant} className='mb-0 text-neutral-200 capitalize'>
                                            {plant}
                                            {index < item.plants.length - 1 && ","}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            ) : null}
            {details.countries_with_plants.some((item) => item.zone === "SAZ") ? (
                <>
                    <div className='mt-2 ms-4 flex flex-row gap-1 items-center'>
                        <RadioButtonCheckedIcon className='!h-4 !w-4' />
                        <p className='mb-0 text-neutral-200 capitalize font-medium'>{t("userModule.saz_zone")}:</p>
                    </div>
                    {details.countries_with_plants.filter((item) => item.zone === "SAZ").map((item, index) => (
                        <div key={index+1} className='ms-8 flex flex-col gap-1'>
                            <div className='flex flex-row gap-1 items-center'>
                                <PublicIcon className='!h-4 !w-4' />
                                <p className='mb-0 text-neutral-200 capitalize font-medium'>{item.country}:</p>
                                <div className='flex flex-wrap gap-1'>
                                    {item.plants.map((plant, index) =>(
                                        <p key={plant} className='mb-0 text-neutral-200 capitalize'>
                                            {plant}
                                            {index < item.plants.length - 1 && ","}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            ) : null}
        </div>
      </div>
    </div>
  )
}

ModalUserDetails.propTypes = {
    details: PropTypes.any
}

export default ModalUserDetails
