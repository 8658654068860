// Import the resources
import React, {useContext} from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { PlantsContext } from '../../context/PlantsContext';

const ProtectedRoutes = () => {

  /**
   * @context
   * @description Access to get global state of PlantsContext
  */
  const {plants} = useContext(PlantsContext);

  /**
   * @condition
   * @description Validate if the array of plants has a quantity less than 1 to enroute to initial view
  */
  if (plants.length < 1) {
    return <Navigate to="/" />
  }

  return (
    <Outlet />
  )
}

export default ProtectedRoutes
