import { createSlice } from "@reduxjs/toolkit";

/**
 * @constant
 * @type {object}
 * @property {Array} categoriesModal - An array to store modal categories.
 * @property {Array} imputationsModal - An array to store modal imputation levels.
 * @property {Array} vendorsModal - An array to store modal vendors.
 * @property {Object} priceSpend - An object to store prices.
 * @property {Array} categoriesGrafico - An array to store the categories for the charts.
 * @property {Array} vendorsGrafico - An array to store the vendors for the charts.
 * @property {Array} imputationEscogido - An array to store the imputation levels selected.
 * @property {Array} vendorsEscogido - An array to store the vendors selected.
 * @property {Array} categoriesEscogido - An array to store the categories selected.
 * @property {string} yearEscogido - A string to store the year selected.
 * @property {string} monthEscogido - A string to store the month selected.
 * @property {string} paisEscogido - A string to store the country selected.
*/
const initialState = {
    categoriesModal:[],
    imputationsModal:[],
    vendorsModal: [],
    priceSpend: {
        "totalSpend": 0,
        "received": 0,
        "pending": 0
    },
    chartCategories: [],
    chartVendors: [],
    imputationSelected: [],
    vendorsSelected: [],
    categoriesSelected: [],
    yearSelected: "",
    monthSelected: "",
    countrySelected: ""
}

export const spendSlice = createSlice({
    name: 'negotiations',
    initialState,
    reducers: {
        /**
         * @function addVendorsModal
         * @description A reducer to update vendorsModal.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addVendorsModal: (state, action) => {
            state.vendorsModal = action.payload;
        },

        /**
         * @function addCategoriesModal
         * @description A reducer to update categoriesModal.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addCategoriesModal: (state, action) => {
            state.categoriesModal = action.payload;
        },

        /**
         * @function addImputationsModal
         * @description A reducer to update imputationsModal.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addImputationsModal: (state, action) => {
            state.imputationsModal = action.payload;
        },

        /**
         * @function addPrices
         * @description A reducer to update priceSpend.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addPrices: (state, action) => {
            state.priceSpend = action.payload;
        },

        /**
         * @function addChartCategories
         * @description A reducer to update chartCategories.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addChartCategories: (state, action) => {
            state.chartCategories = action.payload;
        },

        /**
         * @function addChartVendors
         * @description A reducer to update chartVendors.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addChartVendors: (state, action) => {
            state.chartVendors = action.payload;
        },

        /**
         * @function addImputationSelected
         * @description A reducer to update imputationSelected.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addImputationSelected: (state, action) => {
            state.imputationSelected = action.payload;
        },

        /**
         * @function addVendorsSelected
         * @description A reducer to update vendorsSelected.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addVendorsSelected: (state, action) => {
            state.vendorsSelected = action.payload;
        },

        /**
         * @function addCategoriesSelected
         * @description A reducer to update categoriesSelected.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addCategoriesSelected: (state, action) => {
            state.categoriesSelected = action.payload;
        },

        /**
         * @function addYearSelected
         * @description A reducer to update yearSelected.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addYearSelected: (state, action) => {
            state.yearSelected = action.payload;
        },

        /**
         * @function addMonthSelected
         * @description A reducer to update monthSelected.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addMonthSelected: (state, action) => {
            state.monthSelected = action.payload;
        },

        /**
         * @function addPaisSelected
         * @description A reducer to update paisSelected.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addCountrySelected: (state, action) => {
            state.countrySelected = action.payload;
        },

        /**
         * @function resetStateSpend
         * @description A reducer to reset the states
        */
        resetStateSpend: () => initialState
    }
})

export const { addVendorsModal, addCategoriesModal, addImputationsModal, addPrices, addChartCategories, 
    addChartVendors, addImputationSelected, addVendorsSelected, addCategoriesSelected, addYearSelected, 
    addMonthSelected, addCountrySelected, resetStateSpend } = spendSlice.actions;
export default spendSlice.reducer;