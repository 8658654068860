import React from 'react'
import PropTypes from 'prop-types';

const ActiveDotMaterialInventory = (props) => {
    const { cx, cy, payload } = props;

    if (payload.stock !== 0) {
        return (
            <circle cx={cx} cy={cy} r={6} fill="#3c8dc0" stroke="#ffffff" strokeWidth={1} />
        );
    }

    return null;
}

ActiveDotMaterialInventory.propTypes = {
    cx: PropTypes.number,
    cy: PropTypes.number,
    payload: PropTypes.object
}

export default ActiveDotMaterialInventory