import { configureStore } from "@reduxjs/toolkit";
import policyReducer from "./policySlice";
import saleSlice from "./saleSlice";
import spendSlice from "./spendSlice";
import summarySlice from "./summarySlice";
import materialSlice from "./materialSlice";
import blockSlice from "./blockSlice";
import userSlice from "./userSlice"

/**
 * @typedef {Object} ReducerObject
 * @property {Function} policy - Reducer for policy related state.
 * @property {Function} sale - Reducer for sale related state.
 * @property {Function} negotiation - Reducer for negotiation related state.
 * @property {Function} summary - Reducer for summary related state.
*/
export const store = configureStore({
    reducer: {
        policy: policyReducer,
        sale: saleSlice,
        negotiation: spendSlice,
        summary: summarySlice,
        material: materialSlice,
        block: blockSlice,
        user: userSlice
    }
})