import React from 'react';
import '../../../styles/policy_style.css'
import loadLogo from '../../../images/loadLogo.gif'
import { useTranslation } from 'react-i18next';
import LabelDemands from '../labelsCharts/labelDemands';
import LabelWarning from '../labelsCharts/labelWarning';
import { BarChart, Bar, XAxis, ResponsiveContainer, LabelList } from 'recharts';
import PropTypes from 'prop-types';

const ModalDemands = ({demandsDataModal, loading}) => {
    
    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
    */
    const { t } = useTranslation();
  
    return (
        <>
            {!loading ? (
                <div className='mx-4 rounded-top rounded-bottom h-full'>
                    {/* Space if dataModal is not empty */}
                    <div className='flex justify-center items-center h-0.25/5 rounded-t bg-neutral-300'>
                        <p className='mb-0 font-semibold text-center'>{t("policyManagement.titleDemands")}</p>
                    </div>
                    <div className='h-4.75/5 bg-neutral-200'>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart width={150} height={10} data={demandsDataModal} barGap={10}>
                                <Bar dataKey="demand" fill="#FFC629">
                                    <LabelList dataKey="is_atypic" position="insideTop" content={LabelWarning} />
                                    <LabelList dataKey="demand" position="center" content={LabelDemands} />
                                </Bar>
                                <XAxis dataKey="period" className='text-xs 3xl:text-base 4xl:text-lg' />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            ):(
                <div  className='flex flex-col h-full justify-center'>
                    <div className='flex flex-row w-full justify-center'>
                        <img src={loadLogo} alt='Carga Logo' className='load-logo-modal' />
                    </div>
                </div>
            )}
        </>
        
    )
}

ModalDemands.propTypes = {
    loading: PropTypes.bool,
    demandsDataModal: PropTypes.array
}

export default ModalDemands
