import axios from "axios";
import Cookies from 'js-cookie';

/**
 * @constant
 * @description The domain for the API, fetched from environment variables.
 * @type {string}
*/
const domain = process.env.REACT_APP_API_URL

/**
 * @constant
 * @description The general API param
 * @type {string}
*/
const apiParam = process.env.REACT_APP_API_PARAM

/**
 * @function getBlock
 * @description Sends a GET request to the block and unblobk endpoint of the API.
 * @param {Object} country - The country selected by the user.
 * @returns {Promise} Axios promise for the HTTP request, which resolves to the response data.
*/
function getBlockInitial(country, page) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/ipa_app/marc/?country=${country.normalCountry}&page_size=200&page=${page}&${apiParam}`, config).then(res => {
        const responseData = res.data;
        return responseData;
    });
}

/**
 * @function getBlockContinue
 * @description Sends a GET request to the block and unblobk endpoint of the API.
 * @param {Object} country - The country selected by the user.
 * @returns {Promise} Axios promise for the HTTP request, which resolves to the response data.
*/
function getBlockContinue(plant, planner, material, page, zone) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/ipa_app/marc/?plant=${plant}&planner=${planner}&material=${material}&page_size=200&page=${page}&${apiParam}`, config).then(res => {
        const responseData = res.data;
        return responseData;
    });
}

/**
 * @function postBlock
 * @description Sends a POST request to the block and unblobk endpoint of the API.
 * @param {Object} args - The arguments to be sent in the body of the POST request.
 * @returns {Promise} Axios promise for the HTTP request, which resolves to the response data.
*/
function postBlock(page, args) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.post(`${domain}/ipa_app/marc/?page=${page}&page_size=200&${apiParam}`, args, config)
}

function postApproveBlock(args) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.post(`${domain}/ipa_app/marc_approve/?${apiParam}`, args, config)
}

export { getBlockInitial, getBlockContinue, postBlock, postApproveBlock };