import React from 'react'
import PropTypes from 'prop-types';

const LegendInventory = ({legends}) => {
  return (
    <div className='flex justify-center w-full gap-4 mb-1'>
      {legends.map((entry) => (
        <div key={entry.id} className='flex items-center gap-1'>
          <div style={{ borderColor: `${entry.color}`}} className='w-6 xl:w-10 h-2 bg-neutral-300 border-4 border-solid'></div>
          <div>
            <p className='mb-0 text-xs 4xl:text-sm'>
              {entry.text}
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

LegendInventory.propTypes = {
    legends: PropTypes.array
}

export default LegendInventory