import axios from "axios";
import Cookies from 'js-cookie';

/**
 * @constant
 * @description The domain for the API, fetched from environment variables.
 * @type {string}
*/
const domain = process.env.REACT_APP_API_URL

/**
 * @constant
 * @description The general API param
 * @type {string}
*/
const apiParam = process.env.REACT_APP_API_PARAM

/**
 * @function getNegotiation
 * @description Sends a GET request to the get-negotiation endpoint of the API.
 * @param {Object} country - The country object which contains the country code.
 * @returns {Promise} Axios promise for the HTTP request, the data is displayed on these objects:
 * vendors, categories, totalPxq, totalIngresado and totalPendiente.
*/
function getNegotiation(country) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/ipa_app/get-negotiation/${country.countryNego}/?${apiParam}`, config).then(res => {
        const responseData = res.data;
        const vendors = responseData.vendors
        const categories = responseData.categories
        const totalPxq = responseData.valor_total_pxq_po
        const totalEntered = responseData.valor_total_ingresado_po
        const totalPending = responseData.valor_total_pend_po
        return {vendors, categories, totalPxq, totalEntered, totalPending};
    });
}

/**
 * @function getIniNegotiation
 * @description Sends a GET request to the get-ini-negotiation endpoint of the API.
 * @param {Object} country - The country object which contains the country code.
 * @returns {Promise} Axios promise for the HTTP request, the data is displayed on these objects:
 * vendors, categories and imputations.
*/
function getIniNegotiation(country) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/ipa_app/get-ini-negotiation/${country.normalCountry}/?${apiParam}`, config).then(res => {
        const responseData = res.data;
        const vendors = responseData.Vendors
        const categories = responseData.Category
        const imputations = responseData.Imputation
        return {vendors, categories, imputations};
    });
}

/**
 * @function postNegotiation
 * @description Sends a POST request to the get-negotiation endpoint of the API.
 * @param {Object} args - The arguments to be sent in the body of the POST request.
 * @param {Object} country - The country object which contains the country code.
 * @returns {Promise} Axios promise for the HTTP request.
*/
function postNegotiation(args, country) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.post(`${domain}/ipa_app/get-negotiation/${country.countryNego}/?${apiParam}`, args, config);
}

/**
 * @function postDownload
 * @description Sends a POST request to the get-negotiation-download endpoint of the API.
 * @param {Object} args - The arguments to be sent in the body of the POST request.
 * @returns {Promise} Axios promise for the HTTP request.
*/
function postDownload(args) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.post(`${domain}/ipa_app/get-negotiation-download/?${apiParam}`, args, config);
}


export { getNegotiation, getIniNegotiation, postNegotiation, postDownload };