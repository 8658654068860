import { createSlice } from "@reduxjs/toolkit";

/**
 * @constant
 * @type {object}
 * @property {Array} summaries - An array to store summaries.
*/
const initialState = {
    summaries:[]
}

export const summarySlice = createSlice({
    name: 'summaries',
    initialState,
    reducers: {
        /**
         * @function addSummaries
         * @description A reducer to update summaries.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addSummaries: (state, action) => {
            state.summaries = action.payload;
        },

        /**
         * @function resetStateSummary
         * @description A reducer to reset the states
        */
        resetStateSummary: () => initialState
    }
})

export const { addSummaries, resetStateSummary } = summarySlice.actions;
export default summarySlice.reducer;