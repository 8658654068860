import React from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import es from '../src/i18n/spanish/es.json';
import en from '../src/i18n/english/en.json';
import po from '../src/i18n/portuguese/po.json'
import { MsalProvider } from '@azure/msal-react';

/**
 * @hook i18next.use(initReactI18next).init
 * @type {function}
 * @description Hook to make the translations using the en.js and es.js files
 * @param {string} lng - The default language for the app. In this case, it is set in English ("en").
 * @param {object} interpolation - Interpolation settings. Here, the escape functionality (escapeValue: false) is 
 * disabled to allow the use of HTML in translations.
 * @param {object} resources - Translation resources for Spanish (es) and English (en). These resources are imported 
 * from the corresponding JSON files (es.json and en.json)
 */
i18next.use(initReactI18next).init({
  lng: "en",
  interpolation: {
    escapeValue: false
  },
  resources: {
    es: {
      translation: es
    },
    en: {
      translation: en
    },
    po: {
      translation: po
    }
  }
})

const clientId = process.env.REACT_APP_SERVICE_CLIENT_ID
const tenantId = process.env.REACT_APP_SERVICE_TENANT_ID
const redirectUri = process.env.REACT_APP_REDIRECT_URI

const configuration = {
  auth: {
    clientId: clientId,
    authority: "https://login.microsoftonline.com/" + tenantId,
    redirectUri: redirectUri
  },
  scopes: ["openid", "user.read", "api://" + clientId + "/profile.read"]
};
export const msalInstance = new PublicClientApplication(
  configuration
);

msalInstance.addEventCallback((event) => {
  if (event.eventType === 'msal:loginSuccess' && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <App />
    </Provider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
