import React from 'react'
import { useTranslation } from 'react-i18next';
import ForwardIcon from '@mui/icons-material/Forward';
import IPALogo from '../../images/IPALogo.svg'
import signo from '../../images/Vector.png'
import name from '../../images/nameNewLogin.png'
import { useMsal } from "@azure/msal-react";
import PropTypes from 'prop-types';

const NewLogin = ({sendLoginSuccess}) => {

    const { t } = useTranslation();

    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginPopup().then(response => {
            sendLoginSuccess(true)
        }).catch(error => {
            sendLoginSuccess(false)
        });
    }

  return (
    <div className='flex flex-col justify-center h-full w-full image-wallpaper'>
        <div className='flex flex-row justify-center w-full py-10'>
            <img src={signo} className='w-0.50/5' alt='signo-abi'/>
        </div>
        <div className='flex flex-row justify-center w-full py-10'>
            <button onClick={() => handleLogin()} className='rounded font-semibold py-1 border w-0.50/5 border-black bg-brand-300 hover:bg-brand-200 duration-300'>{t("accessButton")} <ForwardIcon /></button>
        </div>
        <div className='flex flex-row justify-center w-full py-4'>
            <img src={IPALogo} className='w-0.50/5' alt='signo-abi'/>
        </div>
        <div className='flex flex-row justify-center w-full py-10'>
            <img src={name} className='w-0.50/5' alt='signo-abi'/>
        </div>
    </div>
  )
}

NewLogin.propTypes = {
    sendLoginSuccess: PropTypes.func
}

export default NewLogin