// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/newLoginWallpaper.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @description This class establish the image font for the view.
 */
.image-wallpaper {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-repeat: no-repeat;
}

.dialog-important {
    width: calc(12% * var(--quantity-countries)) !important;
    max-width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/initial_style.css"],"names":[],"mappings":"AAAA;;EAEE;AACF;IACI,yDAAwD;IACxD,sBAAsB;IACtB,4BAA4B;AAChC;;AAEA;IACI,uDAAuD;IACvD,0BAA0B;AAC9B","sourcesContent":["/**\n * @description This class establish the image font for the view.\n */\n.image-wallpaper {\n    background-image: url('../images/newLoginWallpaper.png');\n    background-size: cover;\n    background-repeat: no-repeat;\n}\n\n.dialog-important {\n    width: calc(12% * var(--quantity-countries)) !important;\n    max-width: 100% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
