import React from 'react'
import PropTypes from 'prop-types';

const DotMaterialDemands = (props) => {
    const { cx, cy, payload } = props;

    if (payload.demand !== 0) {
        return (
            <circle cx={cx} cy={cy} r={3} fill="#ffffff" stroke="#3c8dc0" strokeWidth={1} />
        );
    }

    return null;
}

DotMaterialDemands.propTypes = {
    cx: PropTypes.number,
    cy: PropTypes.number,
    payload: PropTypes.object
}

export default DotMaterialDemands