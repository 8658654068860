import React from 'react'
import { addThousandPoints } from '../../../models/generics';
import PropTypes from 'prop-types';

const LabelPolicy = (props) => {
    
  const { x, y, value, height, width  } = props;
  
  const yPos = isNaN(y) ? 0 : y + height / 2;

  return (
    <text x={x + width / 2} y={yPos} className='text-black font-semibold text-xs xl:text-base 4xl:text-xl' textAnchor="middle" dominantBaseline="middle">
        {addThousandPoints(value)}
    </text>
  )
}

LabelPolicy.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  value: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
}

export default LabelPolicy
