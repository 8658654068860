import axios from "axios";
import Cookies from 'js-cookie';

/**
 * @constant
 * @description The domain for the API, fetched from environment variables.
 * @type {string}
*/
const domain = process.env.REACT_APP_API_URL

/**
 * @constant
 * @description The general API param
 * @type {string}
*/
const apiParam = process.env.REACT_APP_API_PARAM

/**
 * @function getUsers
 * @description Sends a GET request to get all users in the database
 * @returns {Promise} Axios promise for the HTTP request, which resolves to the response data.
*/
function getUsers() {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/users/?${apiParam}`, config).then(res => {
        const responseData = res.data;
        return responseData;
    });
}


function getAllPlants() {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/ipa_app/get-all-plants/?${apiParam}`, config).then(res => {
        const responseData = res.data;
        return responseData;
    });
}

function getAllCountries() {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/ipa_app/get-all-countries/?${apiParam}`, config).then(res => {
        const responseData = res.data;
        return responseData;
    });
}

function getAllRoles() {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/users/role/?${apiParam}`, config).then(res => {
        const responseData = res.data;
        return responseData;
    });
}

function postUser(args) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.post(`${domain}/users/?${apiParam}`, args, config);
}

function putUser(args) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.put(`${domain}/users/?${apiParam}`, args, config);
}

export { getUsers, getAllPlants, getAllCountries, getAllRoles, postUser, putUser };