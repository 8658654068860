import React from 'react'
import { addThousandPoints } from '../../../models/generics';
import PropTypes from 'prop-types';

const TooltipNegoVendor = ({ active, payload, label }) => {
    if (active && payload?.length) {
        return (
            <div className="flex flex-col items-center p-2 rounded-lg bg-white">
                <p className="font-semibold mb-0">{`Vendor: ${label}`}</p>
                <p className="font-semibold mb-0 text-start">{`Value: $ ${addThousandPoints(payload[0].value)}`}</p>
            </div>
        );
    }
}

TooltipNegoVendor.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
    label: PropTypes.string
}

export default TooltipNegoVendor
