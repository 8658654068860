import React from 'react'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ModalDescription = ({material}) => {

    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
    */
    const { t } = useTranslation();

    return (
        <div className='flex flex-col w-full h-full gap-y-3'>
            <div className='flex flex-row gap-2 items-center'>
                <div className='small-square'></div>
                <div className='flex flex-row gap-1'>
                    <p className='mb-0 font-semibold text-neutral-200'>{t("policyManagement.type")}:</p>
                    <p className='mb-0 text-neutral-200'>{material.type}</p>
                </div>
            </div>
            <div className='flex flex-col gap-y-1 w-full'>
                <div className='flex flex-row gap-2 items-center'>
                    <div className='small-square'></div>
                    <p className='mb-0 font-semibold text-neutral-200'>{t("policyManagement.description")}:</p>
                </div>
                <div className='w-full'>
                    <p className='mb-0 ms-5 text-neutral-200'>{material.description === null ? t("policyManagement.gericDescription") : material.description}</p>
                </div>
            </div>
        </div>
    )
}

ModalDescription.propTypes = {
    material: PropTypes.object
}

export default ModalDescription
