import React from 'react'
import alertIcon from '../../../images/alertIcon.svg'
import PropTypes from 'prop-types';

const LabelWarning = (props) => {

    const { x, y, value, width } = props;

    return (
        <foreignObject x={x + width / 2 - 10.5} y={y+10} width="50%" height="50%">
            {value === "True" ? (<img src={alertIcon} alt="alert" />): null} 
        </foreignObject>
    )
}

LabelWarning.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    value: PropTypes.string,
    width: PropTypes.number,
}

export default LabelWarning
