import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    plants: [],
    countries: [],
    roles: [],
    userList:[]
}

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        /**
         * @function addPlants
         * @description A reducer to update plants.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addPlants: (state, action) => {
            state.plants = action.payload;
        },

        /**
         * @function addCountries
         * @description A reducer to update countries.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addCountries: (state, action) => {
            state.countries = action.payload;
        },

        /**
         * @function addRoles
         * @description A reducer to update roles.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addRoles: (state, action) => {
            state.roles = action.payload;
        },

        /**
         * @function addUserList
         * @description A reducer to update userList.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addUserList: (state, action) => {
            state.userList = action.payload;
        },

        /**
         * @function updateUserList
         * @description A reducer to update userList.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        updateUserList: (state, action) => {
            state.userList.push(action.payload);
        },

        /**
         * @function updateUserByEmail
         * @description A reducer to update a user in the userList based on their email.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action. It should have a user object with the 'email' key.
        */
        updateUserByEmail: (state, action) => {
            const updatedUser = action.payload;
            const index = state.userList.findIndex(user => user.email === updatedUser.email);
            if (index !== -1) {
                state.userList[index] = updatedUser;
            }
        },

        /**
         * @function resetStateUser
         * @description A reducer to reset the states
        */
        resetStateUser: () => initialState
    }
})

export const { addPlants, addCountries, addRoles, addUserList, updateUserList, updateUserByEmail, resetStateUser } = userSlice.actions;
export default userSlice.reducer;