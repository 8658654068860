// Import the resources
import React, {useState, useEffect, useContext} from 'react';
import Cookies from 'js-cookie';
import { useMsal } from '@azure/msal-react';
import '../../styles/initial_style.css';
import ModalInitial from '../../components/pure/modals/modalInitial';
import { postLogin, getToken } from '../../models/login';
import Loading from '../../components/pure/loading';
import Swal from 'sweetalert2';
import { CountriesContext } from '../../context/CountriesContext';
import { PermissionContext } from '../../context/PermissionContext';
import { ZoneContext } from '../../context/ZoneContext';
import { RoleContext } from '../../context/RoleContext';
import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { resetStateSummary } from '../../redux/summarySlice';
import { resetStatePolicy } from '../../redux/policySlice';
import { resetStateSales } from '../../redux/saleSlice';
import { resetStateSpend } from '../../redux/spendSlice';
import { resetStateMaterial } from '../../redux/materialSlice';
import { resetStateUser } from '../../redux/userSlice';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
/**
 * @component
 * @description This component show the app's initial page.
*/
const InitialPage = ({sendLoginSuccess}) => {

    const { accounts } = useMsal();

    const { t } = useTranslation();

    const dispatch = useDispatch()

    /**
     * @const
     * @description Const to save the text to show while the view is loading
    */
    const loadText = t("cargaTextInitial")

    /**
     * @context
     * @description Access to update a global state for CountriesContext
    */
    const {setCountries} = useContext(CountriesContext);

    /**
     * @context
     * @description Access to update a global state for PermissionContext
    */
    const {setPermissions} = useContext(PermissionContext);

    /**
     * @context
     * @description Access to update a global state for RoleContext
    */
    const {setRole} = useContext(RoleContext);

    /**
     * @context
     * @description Access to update a global state for ZoneContext
    */
    const {setZone} = useContext(ZoneContext);

    /**
     * @context
     * @description Access to update a global state for UserContext
    */
    const {setUser} = useContext(UserContext);
    
    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local State to show the logoCarga
    */
    const [loading, setLoading] = useState(false);

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local state to show modal initial to select a country according to the user
    */
    const [initialModal, setInitialModal] = useState(false);

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local state to show modal initial to select a country according to the user
    */
    const [modalZone, setModalZone] = useState(false);

    /**
     * @state
     * @type {number}
     * @default 0
     * @description Local state to know the countries quantity based on the endpoint response
    */
    const [quantityCountries, setQuantityCountries] = useState(0);

    /**
     * @function
     * @name openInitialModal
     * @description Function to update the value of the login state and modal state
    */
    function openInitialModal() {
        setInitialModal(!initialModal);
    }

    /**
     * @function
     * @name openZoneModal
     * @description Function to update the value of the login state and modal state
    */
    function openZoneModal() {
        setModalZone(!modalZone);
    }

    /**
     * @function
     * @name logueo
     * @description Function to execute the login endpoint with the email and password from the login modal
    */
    function logueo(token, email) {
        setLoading(true)
        const data = {
            "email": email
        }
        postLogin(data, token).then((response) => {
            const countriesPlants = response.data.countries_with_plants.filter((item) => item.plants.length > 0)
            if (countriesPlants.length > 0) {
                setQuantityCountries(countriesPlants.length)
                setCountries(countriesPlants)
                setPermissions(response.data.permissions)
                setRole(response.data.role)
                setUser(email)
                setLoading(false)
                sendLoginSuccess(true)
                openInitialModal()
            } else {
                setLoading(false)
                Swal.fire({
                    icon: "error",
                    html:`<p class="mb-0 font-semibold text-neutral-300">${t("emptyText")}</p>`,
                    title: `${t("emptyTitle")}`,
                    showConfirmButton: true,
                    confirmButtonColor: '#FFCF26',
                    allowOutsideClick: false,
                }).then(() => {
                    setModalZone(true);
                })
            }
        }).catch((error)=> {
            sendLoginSuccess(false)
            Cookies.remove('email');
            Cookies.remove('token');
            if (error.response.status === 401) {
                Swal.fire({
                    icon: "error",
                    html:`
                    <p class="mb-0 font-semibold text-neutral-300">${t("firtLoginFail")} <a class="underline text-blue-500">luis.suarez@gmodelo.com.mx</a> ${t("secondLoginFail")}</p>
                    `,
                    title: `${t("loginTitle")}`,
                    showConfirmButton: true,
                    confirmButtonColor: '#FFCF26',
                    confirmButtonText: 'Close'
                });
            } else {
                Swal.fire({
                    icon: "error",
                    html:`<p class="mb-0 font-semibold text-neutral-300">${t("initialFail")}</p>`,
                    title: `${t("initialTitle")}`,
                    showConfirmButton: true,
                    confirmButtonColor: '#FFCF26',
                    confirmButtonText: 'Close'
                });
            }
            setLoading(false)
        })
    }

    const handleSelectCountry = (value) => {
        setZone(value)
        openZoneModal()
        logueo(Cookies.get('token'), Cookies.get('email'));
    }

    useEffect(() => {
        dispatch(resetStateSummary());
        dispatch(resetStatePolicy());
        dispatch(resetStateSales());
        dispatch(resetStateSpend());
        dispatch(resetStateMaterial());
        dispatch(resetStateUser())
    }, []);

    /**
     * @effect
     * @description This effect is executed when the component is opened. 
     * Initializes the CountriesContext state as an empty array and UserContext as an empty string
    */
    useEffect(() => {
        setLoading(true)
        async function fetchAndLogIn() {
            try {
                const res = await getToken(accounts[0]);
                const expireDate = new Date(res.expiresOn)
                const token = res.tokenType+ " " + res.idToken;
                Cookies.set('email', res.account.username, { expires: expireDate });
                Cookies.set('token', token, { expires: expireDate });
                Cookies.set('accessToken', res.accessToken, { expires: expireDate });
                // openZoneModal()
                logueo(token, res.account.username);
                // setCountries([]);
            } catch (error) {
                setLoading(false)
            }
        };
        
        fetchAndLogIn();
    }, [accounts]);

    return (
        <div className='flex flex-row justify-between gap-3 h-full image-wallpaper'>
            {/* Zone Modal */}
            <Dialog open={modalZone} classes={{ paper: "!max-w-full !w-1/2 !rounded-lg" }}>
                <DialogTitle className='bg-brand-300'>
                    <p className='mb-0 font-bold text-center'>Select your zone</p>
                </DialogTitle>
                <DialogContent className='bg-neutral-400 !py-2 !px-2'>
                    <div className='w-full flex flex-row justify-between gap-2'>
                        <button onClick={() => handleSelectCountry("SAZ")} className='rounded font-semibold py-2 w-1/2 border-none bg-neutral-300 hover:bg-neutral-200'>SAZ</button>
                        <button onClick={() => handleSelectCountry("MAZ")} className='rounded font-semibold py-2 w-1/2 border-none bg-neutral-300 hover:bg-neutral-200'>MAZ</button>
                    </div>
                </DialogContent>
            </Dialog>
            {/* Initial Modal */}
            <Dialog open={initialModal} classes={{ paper: "dialog-important" }} style={{ "--quantity-countries": quantityCountries }}>
                <DialogTitle className='bg-brand-300'>
                    <p className='mb-0 font-bold text-center'>{t("country")}</p>
                </DialogTitle>
                <DialogContent className='bg-neutral-400 !py-2 !px-2'>
                    <ModalInitial />
                </DialogContent>
            </Dialog>
            {/* Space to show logoCarga if the local state "cargando" is true */}
            {loading ? (
                <Loading text={loadText} />
            ): null}
        </div>
    );
}

InitialPage.propTypes = {
    sendLoginSuccess: PropTypes.func
}

export default InitialPage;
