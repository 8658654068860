// Import the resources
import React, {useContext} from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { CountryComponentContext } from '../../../context/CountryComponentContext';

const ProtectedIntercompany = () => {
  
  /**
   * @context
   * @description Access to get global state of CountryComponentContext
  */
  const {country} = useContext(CountryComponentContext)

  /**
   * @condition
   * @description Validate if the country object has a key with the value "PA" or "HO" to enroute to summary view
  */
  if (country.normalCountry==="PA" || country.normalCountry==="HO") {
    return <Navigate to="/inventory/summary" />
  }

  return (
    <Outlet />
  )
}

export default ProtectedIntercompany
