import React from 'react'
import { addThousandPoints } from '../../../models/generics';
import PropTypes from 'prop-types';

const TooltipMaterialInventory = ({ active, payload, label }) => {
    if (active && payload?.length && payload[0].value !== 0) {
        return (
            <div className="flex flex-col items-center p-2 rounded-lg bg-white">
                <p className="font-semibold mb-0 text-xs 2xl:text-sm 3xl:text-base">{`Date: ${new Intl.DateTimeFormat('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(label)}`}</p>
                <p className="font-semibold mb-0 text-start text-xs 2xl:text-sm 3xl:text-base">{`Stock: ${addThousandPoints(payload[0].value)}`}</p>
            </div>
        );
    }

    return null
}

TooltipMaterialInventory.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
    label: PropTypes.number
}

export default TooltipMaterialInventory