import React from 'react'
import { useTranslation } from 'react-i18next';

const ModalRules = () => {
    
    const { t } = useTranslation();

    const rules = [
        "intercompanySales.criterials.primera",
        "intercompanySales.criterials.segunda",
        "intercompanySales.criterials.tercera",
        "intercompanySales.criterials.cuarta"
    ];

  return (
    <div className='flex flex-col h-full w-full'>
        <p className='mb-0 text-neutral-200 4xl:text-lg'>{t("intercompanySales.criterials.initial")}</p>
        <div className='flex flex-col gap-y-3 px-2'>
            {rules.map((item) => (
                <div key={item} className='flex items-center gap-2'>
                    <div className='punto-negro bg-neutral-200'></div>
                    <p className='mb-0 text-neutral-200 4xl:text-lg'>{t(item)}</p>
                </div>
            ))}
        </div>
    </div>
  )
}

export default ModalRules