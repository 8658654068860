import React from 'react'
import PropTypes from 'prop-types';

const LegendMaterialDemand = ({legend}) => {
  return (
    <div className='flex justify-center w-full'>
      <div className='flex items-center gap-1'>
        <div style={{ borderColor: `${legend.color}`}} className='w-10 h-2 bg-neutral-300 border-4 border-solid'></div>
        <div>
          <p className='mb-0 text-xs 4xl:text-sm'>
            {legend.text}
          </p>
        </div>
      </div>
    </div>
  )
}

LegendMaterialDemand.propTypes = {
    legend: PropTypes.object
}

export default LegendMaterialDemand