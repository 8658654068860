import React, {useState, useEffect} from 'react';
import IPALogo from '../../images/IPALogo.svg'
import ABILogo from '../../images/ABILogo.svg'
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

/**
 * @component
 * @description This component show the superior bar with ipa logo and ABI logo
*/
const NavBars = () => {

    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
     */
    const { t } = useTranslation();

    /**
     * @state
     * @type {string}
     * @default en
     * @description Local State to know what language was selected
    */
    const [languageSelected, setLanguageSelected] = useState("en");

    /**
     * @function
     * @name handleClick
     * @description Function to change the value of isToggled
    */
    const handleClick = (language) => {
        setLanguageSelected(language)
    };

    /**
     * @effect
     * @description This effect is executed when the isToggled state is updated. 
     * Use the i18next library to change the language of the texts in the app
    */
    useEffect(() => {
        i18next.changeLanguage(languageSelected)
    }, [languageSelected]);

    return (
        <div className='bg-neutral-500 h-0.50/5 flex flex-row justify-between w-full px-2'>
            {/* Space for the IPA Logo */}
            <div className='flex items-center w-1/4'>
                <div className='ms-40'>
                    <img src={IPALogo} alt="logo"/>
                </div>
            </div>
            {/* Space for the ABI Logo */}
            <div className='flex items-center flex-row justify-end w-1/2'>
                <div>
                    <img src={ABILogo} alt="logo"/>
                </div>
            </div>
            {/* Space for the language toggle */}
            <div className='flex items-center justify-end 2xl:w-1/4 w-1.5/5'>
                <div className="flex justify-between bg-neutral-200 rounded-full h-1/2 w-3/4 duration-500 gap-1">
                    <button onClick={() => handleClick("en")} className={`${languageSelected === "en" ? 'bg-white duration-500': 'hover:bg-neutral-300 duration-500'} rounded-full flex justify-center items-center w-1/2 cursor-pointer`}>
                        <p className='mb-0 text-xs xl:text-sm 4xl:text-xl font-bold'>{t("english")}</p>
                    </button>
                    <button onClick={() => handleClick("es")} className={`${languageSelected === "es" ? 'bg-white duration-500': 'hover:bg-neutral-300 duration-500'} rounded-full flex justify-center items-center w-1/2 cursor-pointer`}>
                        <p className='mb-0 text-xs xl:text-sm 4xl:text-xl font-bold'>{t("spanish")}</p>
                    </button>
                    <button onClick={() => handleClick("po")} className={`${languageSelected === "po" ? 'bg-white duration-500': 'hover:bg-neutral-300 duration-500'} rounded-full flex justify-center items-center w-1/2 cursor-pointer`}>
                        <p className='mb-0 text-xs xl:text-sm 4xl:text-xl font-bold'>{t("portuguese")}</p>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default NavBars;
