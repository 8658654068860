import { createContext, useState, useMemo } from "react";
import PropTypes from 'prop-types';

export const RoleContext = createContext()

/**
 * @context
 * @description This context contains the user's countries
*/
const RoleContextProvider = ({children}) => {

  /**
   * @state
   * @type {array}
   * @default []
   * @description State to save the user's role
  */
  const [role, setRole] = useState("");

  /**
   * @memo
   * @description Create an object that contains the current state of 'role' and a function to 
   * update it ('setRole').
   * This object is passed as a value to the context provider to allow access and modification of the 
   * state of role from child components.
  */
  const data = useMemo(() => ({
    role, 
    setRole
  }), [role, setRole]);

  return (
    <RoleContext.Provider value={data}>
        {children}
    </RoleContext.Provider>
  )
}

RoleContextProvider.propTypes = {
    children: PropTypes.node,
  };

export default RoleContextProvider