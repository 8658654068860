import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    planners: [],
    materials: [],
    blockData:[],
    plannersSelected:"",
    plantsSelected:"",
    page: 1,
    methodUsed: "",
    validateLoad: true,
    plannersModal: [],
    plantsModal: [],
    materialTypesModal: [],
}

export const blockSlice = createSlice({
    name: 'block',
    initialState,
    reducers: {
        /**
         * @function addPlanners
         * @description A reducer to update planners.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addPlanners: (state, action) => {
            state.planners = action.payload;
        },

        /**
         * @function addMaterials
         * @description A reducer to update materials type.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addMaterials: (state, action) => {
            state.materials = action.payload;
        },

        /**
         * @function addBlockData
         * @description A reducer to update block data.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addBlockData: (state, action) => {
            state.blockData = action.payload;
        },

        /**
         * @function addPlannersSelected
         * @description A reducer to update planners Selected data.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addPlannersSelected: (state, action) => {
            state.plannersSelected = action.payload;
        },

        /**
         * @function addPlantsSelected
         * @description A reducer to update plants Selected data.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addPlantsSelected: (state, action) => {
            state.plantsSelected = action.payload;
        },

        /**
         * @function addPage
         * @description A reducer to update the page used.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addPage: (state, action) => {
            state.page = action.payload;
        },

        /**
         * @function addMethodUsed
         * @description A reducer to update the last method used.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addMethodUsed: (state, action) => {
            state.methodUsed = action.payload;
        },

        /**
         * @function addValidateLoad
         * @description A reducer to update the load validation.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addValidateLoad: (state, action) => {
            state.validateLoad = action.payload;
        },

        /**
         * @function addPlannersModal
         * @description A reducer to update the load validation.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addPlannersModal: (state, action) => {
            state.plannersModal = action.payload;
        },

        /**
         * @function addPlantsModal
         * @description A reducer to update the load validation.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addPlantsModal: (state, action) => {
            state.plantsModal = action.payload;
        },

        /**
         * @function addMaterialTypesModal
         * @description A reducer to update the load validation.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addMaterialTypesModal: (state, action) => {
            state.materialTypesModal = action.payload;
        }
    }
})

export const { addPlanners, addMaterials, addBlockData, addPlannersSelected, addPlantsSelected, addPage, addMethodUsed, addValidateLoad, addPlannersModal, addPlantsModal, addMaterialTypesModal } = blockSlice.actions;
export default blockSlice.reducer;