import React from 'react'
import PropTypes from 'prop-types';

const LegendPolicy = ({ payload }) => {
    return (
        <div className='flex flex-row justify-center w-full gap-3'>
            {payload.map((entry) => (
                <div key={entry.id} className='flex gap-1 items-center'>
                    <div className='w-3 h-3' style={{backgroundColor: entry.color }}></div>
                    <span  className='text-black font-semibold text-xs xl:text-base'>
                        {entry.value}
                    </span>
                </div>
                
            ))}
        </div> 
    )
}

LegendPolicy.propTypes = {
    payload: PropTypes.array
}

export default LegendPolicy