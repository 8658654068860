import axios from "axios";
import Cookies from 'js-cookie';

/**
 * @constant
 * @description The domain for the API, fetched from environment variables.
 * @type {string}
*/
const domain = process.env.REACT_APP_API_URL

/**
 * @constant
 * @description The general API param
 * @type {string}
*/
const apiParam = process.env.REACT_APP_API_PARAM

function getIntercompanyPlants(country) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/ipa_app/plants/?country=${country.normalCountry}&${apiParam}`, config).then(res => {
        const responseData = res.data;
        return responseData;
    });
}

/**
 * @function getPlannersPlants
 * @description Sends a GET request to the get-ini-intercom endpoint of the API.
 * @param {Object} country - The country object which contains the country code.
 * @returns {Promise} Axios promise for the HTTP request, the data is displayed on these objects:
 * originPlanners and destinyPlanners.
*/
function getPlannersPlantsIntercompany(country, zone) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/ipa_app/get-ini-intercom/${country.normalCountry}/?zone=${zone}&${apiParam}`, config).then(res => {
        const responseData = res.data;
        const originPlanners = responseData.Planificador_origen
        const destinyPlanners = responseData.Planificador_destino
        const destinyPlants = responseData.Planta
        return {originPlanners, destinyPlanners, destinyPlants};
    });
}

/**
 * @function postIntercompanyByPlant
 * @description Sends a POST request to the get-intercompany endpoint of the API.
 * @param {Object} args - The arguments to be sent in the body of the POST request.
 * @returns {Promise} Axios promise for the HTTP request.
*/
function postIntercompanyByPlant(args) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.post(`${domain}/ipa_app/intercompany-by-plant/?${apiParam}`, args, config);
}

export { getIntercompanyPlants, getPlannersPlantsIntercompany, postIntercompanyByPlant };
