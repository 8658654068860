// Import the resources
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/policy_style.css';
import ModalDemands from './modals/modalDemands';
import ModalDescription from './modals/modalDescription';
import Papa from 'papaparse';
import Swal from 'sweetalert2';
import alertIcon from '../../images/alertIcon.svg'
import { postDemandsMonth } from '../../models/policy_data';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Tooltip } from '@mui/material';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import { addThousandPoints } from '../../models/generics';
import { RoleContext } from '../../context/RoleContext';
import { ZoneContext } from '../../context/ZoneContext';
import PropTypes from 'prop-types';

const TablePolicy = ({filteredPolicy, filteredDemands, allDataDemands, restartScroll, sendChecked, sendCheckeds, sendTextFilter, sendActiveTable, sendRestarScroll, sendLoadDemandsModal}) => {
    
    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
    */
    const { t } = useTranslation();

    /**
     * @context
     * @description Access to get the global state of the RoleContext
    */
    const {role} = useContext(RoleContext)

    /**
     * @context
     * @description Access to get the global state of the ZoneContext
    */
    const {zone} = useContext(ZoneContext)

    /**
     * @hook
     * @name navigate
     * @type {function}
     * @description It provides the ability to the app with the country selected
    */
    const navigate = useNavigate()

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local State to show the loadLogo in the modal demands
    */
    const [loading, setLoading] = useState(false);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the data for the policy table
    */
    const [policyData, setPolicyData] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the data for the policy table and recover it after reorder it.
    */
    const [originalPolicyData, setOriginalPolicyData] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the data to show in the policy table with the infinite scroll
    */
    const [visiblePolicyData, setVisiblePolicyData] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the data for the demands table
    */
    const [demandsData, setDemandsData] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the data for the demands table and recover it after reorder it.
    */
    const [demandsDataOriginal, setDemandsDataOriginal] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the data to show in the demands table with the infinite scroll
    */
    const [visibleDemandsData, setVisibleDemandsData] = useState([]);

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local State to know if all register in policy table are approved
    */
    const [allMarked, setAllMarked] = useState(false);

    /**
     * @state
     * @type {number}
     * @default 100
     * @description Local States to save the max quantity to show in the policy and demands table with the infinite scroll
    */
    const [policyPageSize, setPolicyPageSize] = useState(100);
    const [pageDemandsSize, setPageDemandsSize] = useState(100);

    /**
     * @state
     * @type {boolean}
     * @default true
     * @description Local State to show the policy table
    */
    const [showPolicy, setShowPolicy] = useState(true);

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local State to show the demands table
    */
    const [showDemands, setShowDemands] = useState(false);

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local State to show the modal of demands table
    */
    const [modal, setModal] = useState(false);

    /**
     * @function
     * @name openModal
     * @description Function to open the modal of demands table
    */
    const openModal = () => setModal(!modal);

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local state to show the material description modal
    */
    const [modalDescription, setModalDescription] = useState(false);

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local state to show the material description modal
    */
    const [material, setMaterial] = useState({});

    /**
     * @function
     * @name abrirDescription
     * @description Function to open the description modal
    */
    const openDescription = (item) => {
        setModalDescription(!modalDescription)
        const data = {
            "code":item.material,
            "type": item.material_type,
            "description": item.material_desc
        }
        setMaterial(data)
    };

    /**
     * @function
     * @name closeDescription
     * @description Function to close the description modal
    */
    const closeDescription = () => setModalDescription(!modalDescription);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the data to show in the modal of demands table
    */
    const [demandsDataModal, setDemandsDataModal] = useState([]);

    /**
     * @state
     * @type {string}
     * @default ""
     * @description Local States to define the order in the policy table and demands table
    */
    const [orderPolicy, setOrderPolicy] = useState("");
    const [orderDemands, setOrderDemands] = useState("");

    /**
     * @constant
     * @type {array}
     * @description Contain the tooltips used in the policy table's headers
    */
    const tooltipText = [
        t("policyManagement.tooltipUno"),
        t("policyManagement.tooltipDos"),
        t("policyManagement.tooltipTres"),
        t("policyManagement.tooltipCuatro"),
        t("policyManagement.tooltipCinco"),
        t("policyManagement.tooltipSeis"),
        t("policyManagement.tooltipSiete"),
        t("policyManagement.tooltipOcho"),
    ]


    /**
     * @function
     * @description Function to show the demand data in the modal
     * @param {object} data - Data of the demand to show
    */
    function handleModalData(data) {
        sendLoadDemandsModal(true)
        const materialModal = {
            "material":[data.material],
            "plants":[data.plant]
        }
        postDemandsMonth(materialModal).then(response => {
            sendLoadDemandsModal(false)
            setDemandsDataModal(response.data)
            openModal()
        }).catch(error => {
            sendLoadDemandsModal(false)
            if (error.response.status === 401) {
                Swal.fire({
                    icon: 'error',
                    title: t("finishedSessionTitle"),
                    text: t("finishedSessionText"),
                    confirmButtonColor: '#FFC629',
                    confirmButtonText: "LogIn",
                    allowOutsideClick: false
                }).then(() => {
                    navigate("/")
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '¡Oops!',
                    confirmButtonColor: '#FFC629',
                    text: t("ApiFail")
                })
            }
        });
        
    }

    /**
     * @function
     * @description Function to close the modal and clean the dataModal
    */
    function closeModal() {
        openModal()
        setDemandsDataModal([])
    }

    /**
     * @function
     * @description Function to download all data that could be saw in the modal demands
    */
    function handleAllDemandData() {
        setLoading(true)
        const uniqueMaterials = [...new Set(allDataDemands.map(item => item.material))]
        const uniquePlants = [...new Set(allDataDemands.map(item => item.plant))]
        const demandDownload = {
            "material":uniqueMaterials,
            "plants":uniquePlants
        }
        postDemandsMonth(demandDownload).then(response => {
            const downloadData = response.data;
            const fileName = "data_material_demands.csv";
            exportToCSV(downloadData, fileName);
            setLoading(false)
            openModal()
        }).catch(error => {
            openModal()
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('status')) {
                if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: t("finishedSessionTitle"),
                        text: t("finishedSessionText"),
                        confirmButtonColor: '#FFC629',
                        confirmButtonText: "LogIn",
                        allowOutsideClick: false
                    }).then(() => {
                        navigate("/")
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '¡Oops!',
                        confirmButtonColor: '#FFC629',
                        text: t("ApiFail")
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error de Tiempo de Espera',
                    text: 'El servidor tardó demasiado en responder.',
                    confirmButtonColor: '#FFC629',
                    confirmButtonText: "Intentar Nuevamente",
                    allowOutsideClick: false
                })
            }
            setLoading(false)
        });
    }
    
    /**
     * @effect
     * @description This effect is executed when the data prop is updated. 
     * Updates the states:
     * dataTable, dataTableOriginal and orderPolicy
     * If the showPolicy is true place the scroll at the top of the policy table
    */
    useEffect(() => {
        const dataWithOverStock = filteredPolicy.map((obj) => ({
            ...obj,
            siHay: obj.total_stock > obj.sm,
        }));
        if (policyData.length === dataWithOverStock.length) {
            const orderedData = policyData.map(obj1 => {
                const obj2 = dataWithOverStock.find(obj => obj.id === obj1.id);
                if (obj2) {
                  return { ...obj1, approved: obj2.approved };
                } else {
                  return obj1;
                }
            });
            setPolicyData(orderedData)
        }else{
            setPolicyData(dataWithOverStock)
            setOrderPolicy("")
        }
        setOriginalPolicyData(dataWithOverStock)
        
        if (showPolicy === true && restartScroll === true) {
            const scrollContainer = document.getElementById('scroll-policy');
            scrollContainer.scrollTop = 0;
        }
        sendRestarScroll(false)
    }, [filteredPolicy]);

    /**
     * @effect
     * @description This effect is executed when the demands prop is updated. 
     * Updates the states:
     * dataTableDemands, dataTableDemandsOriginal and orderDemands
     * If the showDemands is true place the scroll at the top of the demands table
    */
    useEffect(() => {
        setDemandsData(filteredDemands)
        setDemandsDataOriginal(filteredDemands)
        setOrderDemands("")
        if (showDemands === true) {
            const scrollContainer = document.getElementById('scroll-demands');
            scrollContainer.scrollTop = 0;
        }
    }, [filteredDemands]);

    /**
     * @effect
     * @description This effect is executed when the allDataDemands prop is updated. 
     * Updates the state pageDemandsSize in 100
     * If the showPolicy and showDemands is true place the scroll at the top of the tables
    */
    useEffect(() => {
        setPageDemandsSize(100)
        setPolicyPageSize(100)
        if (showDemands === true || showPolicy === true) {
            const scrollContainerDemands = document.getElementById('scroll-demands');
            scrollContainerDemands.scrollTop = 0;

            const scrollContainerPolicy = document.getElementById('scroll-policy');
            scrollContainerPolicy.scrollTop = 0;
        }
    }, [allDataDemands]);

    /**
     * @effect
     * @description This effect is executed when the showPolicy or showDemands state is updated. 
     * If the showPolicy  is true place the scroll at the top of the policy table.
     * If the showDemands  is true place the scroll at the top of the demands table
    */
    useEffect(() => {
        if (showPolicy === true) {
            const scrollContainer = document.getElementById('scroll-policy');
            scrollContainer.scrollTop = 0;
        }

        if (showDemands === true) {
            const scrollContainer = document.getElementById('scroll-demands');
            scrollContainer.scrollTop = 0;
        }
    }, [showPolicy, showDemands]);

    /**
     * @effect
     * @description This effect is executed when the dataTable state is updated. 
     * Validate if all register have the approved key in false.
     * If all register are approved the todosMarcados state will be true
     * If all register aren't approved the todosMarcados state will be false
     * And update the filteredData state with the data according with the page size to make 
     * infinite scroll
    */
    useEffect(() => {
        const allRegistersMarked = policyData.some(item => item.approved === false);
        if (allRegistersMarked) {
            setAllMarked(false)
        } else {
            setAllMarked(true)
        }
        setVisiblePolicyData(policyData.slice(0, policyPageSize));
    }, [policyData]);

    /**
     * @effect
     * @description This effect is executed when the dataTableDemands state is updated. 
     * And update the visibleDataDemands state with the data according with the pageDemandsSize size to make 
     * infinite scroll
    */
    useEffect(() => {
        setVisibleDemandsData(demandsData.slice(0, pageDemandsSize))
    }, [demandsData]);

    /**
     * @state
     * @type {string}
     * @default ""
     * @description Local state to save the text to filter the data in the tables
    */
    const [textFilter, setTextFilter] = useState("");

    /**
     * @function
     * @name handleFilterChange
     * @description Function to update the filterText state
     * @param {string} event - Text in the input
    */
    const handleFilterChange = (event) => {
        setTextFilter(event.target.value); 
    }

    /**
     * @effect
     * @description This efect execute when filterText, showPolicy or showDemands are updated
     * Send the material entered and define what table going to be updated
    */
    useEffect(() => {
        const data = {
            "material":textFilter,
            "policy":showPolicy,
            "demands":showDemands
        }
        sendTextFilter(data)
    }, [textFilter, showPolicy, showDemands]);

    /**
     * @constant
     * @type {array}
     * @description Show the data filtered with filterText state
    */
    const filteredDataPolicy = policyData.filter(item =>
        item.material.includes(textFilter)
    );
    const filteredDataDemands = demandsData.filter(item =>
        item.material.includes(textFilter)
    );

    /**
     * @function
     * @name handleCheckboxChange
     * @description Function to update the key approved value of the register in the policy table
     * @param {object} element - Register selected
    */
    const handleCheckboxChange = (element) => {
        element.approved = !element.approved
        sendChecked(element)
    };

    /**
     * @function
     * @name handleAllCheckbox
     * @description Function to update the key approved value in all register in the policy table
    */
    const handleAllCheckbox = () => {
        const newArray = filteredPolicy.map((item) => ({...item, approved: !allMarked}));
        sendCheckeds(newArray)
    }

    /**
     * @function
     * @name handlePolicy
     * @description Function to show the policy table
    */
    function handlePolicy() {
        setShowPolicy(true)
        setShowDemands(false)
        sendActiveTable("policy")
    }

    /**
     * @function
     * @name handleDemands
     * @description Function to show the demands table
    */
    function handleDemands() {
        setShowPolicy(false)
        setShowDemands(true)
        sendActiveTable("demands")
    }

    /**
     * @function
     * @name exportToCSV
     * @description Function to clean the policy table data and export it into CSV for the user
     * @param data - Data to export to CSV
     * @param filename - Name of the CSV file
    */
    function exportToCSV(data, filename) {
        function removeHtmlTags(str) {
            return str.replace(/<[^>]*>?/gm, '');
        }
        const cleanedData = data.map(item => {
            const cleanedItem = {};
            for (let key in item) {
                if (typeof item[key] === 'string') {
                    cleanedItem[key] = removeHtmlTags(item[key]); // Remover etiquetas HTML
                } else {
                    cleanedItem[key] = item[key];
                }
            }
            return cleanedItem;
        });
        const csv = Papa.unparse(cleanedData);
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const a = document.createElement('a');
        a.href = csvUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    /**
     * @function
     * @name handleExportMaterial
     * @description Function to export into CSV file the data in demand modal
     * @param material - Number of material
     * @param plant - ID of the plant
    */
    function handleExportMaterial(material, plant) {
        const downloadData = demandsDataModal;
        const fileName = "material_"+material+"_"+plant+"_demands.csv";
        exportToCSV(downloadData, fileName);
        openModal()
    }

    /**
     * @function
     * @description Loads more data into the policy table when the bottom of the page is reached.
    */
    const loadMoreData = () => {
        const nextPageStartIndex = visiblePolicyData.length;
        const remainingItems = policyData.length - nextPageStartIndex;
        const nextPageEndIndex = Math.min(nextPageStartIndex + policyPageSize, nextPageStartIndex + remainingItems);
        if (policyData.length > policyPageSize) {
            const nextData = policyData.slice(nextPageStartIndex, nextPageEndIndex);
            setVisiblePolicyData([...visiblePolicyData, ...nextData]);
            setPolicyPageSize(nextPageEndIndex)
        }
    };

    /**
     * @function
     * @description Loads more data into the demands table when the bottom of the page is reached.
    */
    const loadMoreDataDemands = () => {
        const nextPageStartIndex = visibleDemandsData.length;
        const remainingItems = demandsData.length - nextPageStartIndex;
        const nextPageEndIndex = Math.min(nextPageStartIndex + pageDemandsSize, nextPageStartIndex + remainingItems);
        if (demandsData.length > pageDemandsSize) {
            const nextData = demandsData.slice(nextPageStartIndex, nextPageEndIndex);
            setVisibleDemandsData([...visibleDemandsData, ...nextData]);
            setPageDemandsSize(nextPageEndIndex);
        }
    };

    /**
     * @function
     * @description Handles the scroll event in the scroll container of the policy table
    */
    const handleScroll = () => {
        const scrollContainer = document.getElementById('scroll-policy');
        if (scrollContainer) {
          const scrollTop = scrollContainer.scrollTop;
          const scrollHeight = scrollContainer.scrollHeight;
          const clientHeight = scrollContainer.clientHeight;
          if (scrollHeight - scrollTop - clientHeight <= 1) {
            loadMoreData()
          }
        }
    };

    /**
     * @function
     * @description Handles the scroll event in the scroll container of the demands table
    */
    const handleScrollDemands = () => {
        const scrollContainer = document.getElementById('scroll-demands');
        if (scrollContainer) {
          const scrollTop = scrollContainer.scrollTop;
          const scrollHeight = scrollContainer.scrollHeight;
          const clientHeight = scrollContainer.clientHeight;
          if (scrollHeight - scrollTop - clientHeight <= 1) {
            loadMoreDataDemands()
          }
        }
    };

    /**
     * @effect
     * @description This efect execute when filteredData is updated
     * Assemble and disassemble the component
    */
    useEffect(() => {
        const scrollContainer = document.getElementById('scroll-policy');
        if (scrollContainer) {
          scrollContainer.addEventListener('scroll', handleScroll);
        }
    
        return () => {
          if (scrollContainer) {
            scrollContainer.removeEventListener('scroll', handleScroll);
          }
        };
    }, [visiblePolicyData]);

    /**
     * @effect
     * @description This efect execute when visibleDataDemands is updated
     * Assemble and disassemble the component
    */
    useEffect(() => {
        const scrollContainer = document.getElementById('scroll-demands');
        if (scrollContainer) {
          scrollContainer.addEventListener('scroll', handleScrollDemands);
        }
    
        return () => {
          if (scrollContainer) {
            scrollContainer.removeEventListener('scroll', handleScrollDemands);
          }
        };
    }, [visibleDemandsData]);

    /**
     * @function
     * @name handleOrderByRatio
     * @description Order the data in policy table by ratio
     * @param order - Order specified
    */
    const handleOrderByRatio = (order) => {
        const sortedData = [...policyData];
        if (order === "asc") {
            sortedData.sort((a, b) => a.ratio - b.ratio);
            setPolicyData(sortedData);
            setOrderPolicy("ratio_asc")
        } else {
            sortedData.sort((a, b) => b.ratio - a.ratio);
            setPolicyData(sortedData);
            setOrderPolicy("ratio_desc")
        }
    }

    /**
     * @function
     * @name handleOrderByBenefit
     * @description Order the data in policy table by benefit_usd
     * @param order - Order specified
    */
    const handleOrderByBenefit = (order) => {
        const sortedData = [...policyData];
        if (order === "asc") {
            sortedData.sort((a, b) => a.benefit_usd - b.benefit_usd);
            setPolicyData(sortedData);
            setOrderPolicy("benefit_asc")
        } else {
            sortedData.sort((a, b) => b.benefit_usd - a.benefit_usd);
            setPolicyData(sortedData);
            setOrderPolicy("benefit_desc")
        }
    }

    /**
     * @function
     * @name handleOrderByOverStock
     * @description Order the data in policy table by siHay
     * @param order - Order specified
    */
    const handleOrderByOverStock = (order) => {
        const sortedData = [...policyData];
        if (order === "true") {
            sortedData.sort((a, b) => {
                if (a.siHay && !b.siHay) {
                    return -1;
                }
                if (b.siHay && !a.siHay) {
                    return 1;
                }
            });
            setPolicyData(sortedData);
            setOrderPolicy("over")
        } else {
            sortedData.sort((a, b) => {
                if (!a.siHay && b.siHay) {
                    return -1;
                }
                if (b.siHay && !a.siHay) {
                    return 1;
                }
            });
            setPolicyData(sortedData);
            setOrderPolicy("not_over")
        }
    }

    /**
     * @function
     * @name handleOrderByAtypics
     * @description Order the data in demands table by has_atypics
     * @param order - Order specified
    */
    const handleOrderByAtypics = (order) => {
        const sortedData = [...demandsData];
        if (order === "true") {
            sortedData.sort((a, b) => {
                if (a.has_atypics && !b.has_atypics) {
                    return -1;
                }
                if (b.has_atypics && !a.has_atypics) {
                    return 1;
                }
            });
            setDemandsData(sortedData);
            setOrderDemands("atypic_true")
        } else {
            sortedData.sort((a, b) => {
                if (!a.has_atypics && b.has_atypics) {
                    return -1;
                }
                if (b.has_atypics && !a.has_atypics) {
                    return 1;
                }
            });
            setDemandsData(sortedData);
            setOrderDemands("atypic_false")
        }
    }

    /**
     * @function
     * @name restoredPolicy
     * @description Reestablish the order of the data in policy table
    */
    function restoredPolicy() {
        setPolicyData(originalPolicyData)
        setOrderPolicy("")
    }

    /**
     * @function
     * @name restoredDemands
     * @description Reestablish the order of the data in demands table
    */
    function restoredDemands() {
        setDemandsData(demandsDataOriginal)
        setOrderDemands("")
    }

    return (
        <div className='flex flex-col h-full rounded-lg py-1 gap-y-2 px-2' style={{backgroundColor:"#F0F0F0"}}>
            {/* Space of the modal demands */}
            <Dialog open={modal} classes={{paper: "!max-w-4.25/5 !w-4.25/5 2xl:!max-w-3/5 2xl:!w-3/5 !h-full 2xl:!h-3/4 !rounded-lg !bg-neutral-400"}}>
                <DialogTitle className='flex justify-end w-full'>
                    <IconButton color="inherit" onClick={closeModal} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className='h-4.5/5'>
                    <ModalDemands demandsDataModal={demandsDataModal} loading={loading}></ModalDemands>
                </DialogContent>
                <DialogActions className='p-2'>
                    <div className='flex flex-row gap-3 mx-4 mt-1'>
                        <div>
                            <button onClick={() => handleExportMaterial(demandsDataModal[0].material, demandsDataModal[0].plant)} className='border-none rounded font-semibold py-2 px-4 bg-brand-200 hover:bg-brand-300'>{t("policyManagement.downloadChartButton")}</button>
                        </div>
                        <div>
                            <button onClick={() => handleAllDemandData()} className='border-none rounded font-semibold py-2 px-4 bg-brand-200 hover:bg-brand-300'>{t("policyManagement.downloadAllChartButton")}</button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
            {/* Space of the modal material description */}
            <Dialog open={modalDescription} classes={{paper: "!max-w-1/2 !w-1/2 xl:!max-w-1/3 xl:!w-1/3 3xl:!max-w-1/4 3xl:!w-1/4 !rounded-lg !bg-neutral-400"}}>
                <DialogTitle className='flex justify-between w-full items-center !py-1'>
                    <div className='flex justify-start w-1/2'>
                        <p className='mb-0 font-semibold text-neutral-200'>Material {material.code}</p>
                    </div>
                    <IconButton color="inherit" onClick={closeDescription} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className='h-4.5/5'>
                    <ModalDescription material={material} />
                </DialogContent>
            </Dialog>
            {/* Space of the filter input and buttons to change table */}
            <div className="flex flex-row gap-2 items-center">
                <div className="relative w-3/5 xl:w-3/4 flex items-center">
                    <SearchIcon className='left-3 absolute' />
                    <input onChange={handleFilterChange} type="text" className="py-2 ps-10 pe-2 rounded-lg w-full border-2 border-solid focus:outline-none focus:border-focused placeholder:font-semibold" placeholder={t("policyManagement.table.placeholder")} />
                </div>
                <div className='w-0.75/5 xl:w-0.50/5 h-full'>
                    <button onClick={handlePolicy} className={`${showPolicy ? 'bg-brand-300' : 'bg-brand-200'} h-full text-sm 2xl:text-base rounded hover:bg-brand-300 w-full border-none font-semibold py-2`}>{t("policyManagement.table.policyButton")}</button>
                </div>
                <div className='w-0.75/5 xl:w-0.50/5 h-full'>
                    <button onClick={handleDemands} className={`${showDemands ? 'bg-brand-300' : 'bg-brand-200'} h-full text-sm 2xl:text-base rounded hover:bg-brand-300 w-full border-none font-semibold py-2`}>{t("policyManagement.table.demandsButton")}</button>
                </div>
                {/* Space of the reestablish button for the tables */}
                <div className='w-0.50/5 xl:w-0.25/5 flex justify-center'>
                    <button onClick={showPolicy ? restoredPolicy : restoredDemands} className='flex justify-center items-center bg-brand-300 hover:bg-brand-200 h-10 w-10 border-none rounded-full'>
                        <ReplayIcon />
                    </button>
                </div>
            </div>
            <hr className='w-full'/>
            {/* Space of the policy table */}
            {showPolicy ? (
                <div id="scroll-policy" className='h-full w-full overflow-y-auto'>
                    <table className='w-full'>
                        <thead className='bg-neutral-background z-50 sticky top-0'>
                            <tr>
                                <th><Tooltip title={tooltipText[0]} arrow placement="top"><p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold">{t("policyManagement.table.columns.plant")}</p></Tooltip></th>
                                <th><Tooltip title={tooltipText[1]} arrow placement="top"><p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold">{t("policyManagement.table.columns.material")}</p></Tooltip></th>
                                <th><Tooltip title={tooltipText[2]} arrow placement="top"><p className="mb-2 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold" dangerouslySetInnerHTML={{ __html: t("policyManagement.table.columns.rPointA") }}></p></Tooltip></th>
                                <th><Tooltip title={tooltipText[3]} arrow placement="top"><p className="mb-2 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold" dangerouslySetInnerHTML={{ __html: t("policyManagement.table.columns.rPointP") }}></p></Tooltip></th>
                                <th><Tooltip title={tooltipText[4]} arrow placement="top"><p className="mb-2 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold" dangerouslySetInnerHTML={{ __html: t("policyManagement.table.columns.sMaxA") }}></p></Tooltip></th>
                                <th><Tooltip title={tooltipText[5]} arrow placement="top"><p className="mb-2 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold" dangerouslySetInnerHTML={{ __html: t("policyManagement.table.columns.sMaxP") }}></p></Tooltip></th>
                                <th>
                                    <div className='items-center flex flex-row justify-center'>
                                        <div className='flex justify-center'>
                                            <Tooltip title={tooltipText[6]} arrow placement="top"><p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold">{t("policyManagement.table.columns.variation")} %</p></Tooltip>
                                        </div>
                                        <div className='flex items-center h-full'>
                                            {orderPolicy === "" || (orderPolicy !== "ratio_asc" && orderPolicy !== "ratio_desc")
                                            ? (<IconButton className='!p-0' onClick={() => handleOrderByRatio("asc")}><ArrowCircleUpIcon className='w-full h-full' /></IconButton>):null}
                                            {orderPolicy === "ratio_asc" ? (<IconButton className='!p-0' onClick={() => handleOrderByRatio("desc")}><ArrowCircleUpTwoToneIcon className='w-full h-full' /></IconButton>):null}
                                            {orderPolicy === "ratio_desc" ? (<IconButton className='!p-0' onClick={() => handleOrderByRatio("asc")}><ArrowCircleDownTwoToneIcon className='w-full h-full' /></IconButton>):null}
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className='items-center flex flex-row justify-center'>
                                        <div className='flex justify-center'>
                                            <Tooltip title={tooltipText[7]} arrow placement="top"><p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold">{t("policyManagement.table.columns.variation")} $</p></Tooltip>
                                        </div>
                                        <div className='flex items-center h-full'>
                                            {orderPolicy === "" || (orderPolicy !== "benefit_asc" && orderPolicy !== "benefit_desc")
                                            ? (<IconButton className='!p-0' onClick={() => handleOrderByBenefit("asc")}><ArrowCircleUpIcon className='w-full h-full' /></IconButton>):null}
                                            {orderPolicy === "benefit_asc" ? (<IconButton className='!p-0' onClick={() => handleOrderByBenefit("desc")}><ArrowCircleUpTwoToneIcon className='w-full h-full' /></IconButton>):null}
                                            {orderPolicy === "benefit_desc" ? (<IconButton className='!p-0' onClick={() => handleOrderByBenefit("asc")}><ArrowCircleDownTwoToneIcon className='w-full h-full' /></IconButton>):null}
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className='items-center flex flex-row justify-center'>
                                        <div className='flex justify-center'>
                                            <p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold">Stock</p>
                                        </div>
                                        <div className='flex items-center h-full'>
                                            {orderPolicy === "" || (orderPolicy !== "over" && orderPolicy !== "not_over")
                                            ? (<IconButton className='!p-0' onClick={() => handleOrderByOverStock("true")}><ArrowCircleUpIcon className='w-full h-full' /></IconButton>):null}
                                            {orderPolicy === "over" ? (<IconButton className='!p-0' onClick={() => handleOrderByOverStock("false")}><ArrowCircleUpTwoToneIcon className='w-full h-full' /></IconButton>):null}
                                            {orderPolicy === "not_over" ? (<IconButton className='!p-0' onClick={() => handleOrderByOverStock("true")}><ArrowCircleDownTwoToneIcon className='w-full h-full' /></IconButton>):null}
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className='flex flex-col gap-y-1'>
                                        <div className='flex justify-center w-full'>
                                            <p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold">{ role !== "user" ? t("policyManagement.table.columns.approvedViewWrite") : t("policyManagement.table.columns.approvedOnlyView")}</p>
                                        </div>
                                        <div className='flex justify-center w-full'>
                                            <div>
                                                <input type='checkbox' id='check-head' className="w-4 h-4 2xl:w-5 2xl:h-5" onChange={handleAllCheckbox} checked={allMarked} />
                                            </div>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredDataPolicy.slice(0, policyPageSize).map((item, index) => (
                                <tr key={item.id} className={`${index % 2 === 0 ? 'bg-neutral-background' : 'bg-neutral-200'}`}>
                                    <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{item.plant}</p></td>
                                    <td className='flex justify-center'><button className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center underline text-blue-500" onClick={() => openDescription(item)}>{item.material}</button></td>
                                    <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{addThousandPoints(item.rp)}</p></td>
                                    <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{addThousandPoints(item.rp_new)}</p></td>
                                    <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{addThousandPoints(item.sm)}</p></td>
                                    <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{addThousandPoints(item.sm_new)}</p></td>
                                    <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{addThousandPoints((item.ratio) * 100)} %</p></td>
                                    <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">$ {addThousandPoints(item.benefit_usd)}</p></td>
                                    <td><p className={`my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center ${item.siHay ? "text-red-500": ""}`}>{addThousandPoints(item.total_stock)}</p></td>
                                    <th>
                                        <div className='flex justify-center w-full'>
                                            <input type='checkbox' id='check-body' className="w-4 h-4 2xl:w-5 2xl:h-5" onChange={() => handleCheckboxChange(item)} checked={item.approved === true} />
                                        </div>
                                    </th>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ):(<div id="scroll-policy" className='hidden'></div>)}

            {/* Space of the demands table */}
            {showDemands ? (
                <div id="scroll-demands" className='h-full w-full overflow-y-auto'>
                    <table className='w-full'>
                        <thead  className='bg-neutral-background z-50 sticky top-0'>
                            <tr>
                                <th><p className="mb-0 text-center text-xs xl:text-base 4xl:text-lg font-semibold">{t("policyManagement.table.columns.plant")}</p></th>
                                <th><p className="mb-0 text-center text-xs xl:text-base 4xl:text-lg font-semibold">{t("policyManagement.table.columns.material")}</p></th>
                                <th><p className="mb-0 text-center text-xs xl:text-base 4xl:text-lg font-semibold">{t("policyManagement.table.columns.meanDemand")}</p></th>
                                <th><p className="mb-0 text-center text-xs xl:text-base 4xl:text-lg font-semibold">{t("policyManagement.table.columns.stdDeviation")}</p></th>
                                <th><p className="mb-0 text-center text-xs xl:text-base 4xl:text-lg font-semibold">{t("policyManagement.table.columns.history")}</p></th>
                                <th>
                                    <div className='flex flex-row justify-center'>
                                        <div className='flex justify-center'>
                                            <p className="mb-0 text-center text-xs xl:text-base 4xl:text-lg font-semibold">{t("policyManagement.table.columns.atypic")}</p>
                                        </div>
                                        <div className='flex items-center h-full'>
                                            {orderDemands === "" || (orderDemands !== "atypic_true" && orderDemands !== "atypic_false")
                                            ? (<IconButton className='!p-0' onClick={() => handleOrderByAtypics("true")}><ArrowCircleUpIcon className='w-full h-full' /></IconButton>):null}
                                            {orderDemands === "atypic_true" ? (<IconButton className='!p-0' onClick={() => handleOrderByAtypics("false")}><ArrowCircleUpTwoToneIcon className='w-full h-full' /></IconButton>):null}
                                            {orderDemands === "atypic_false" ? (<IconButton className='!p-0' onClick={() => handleOrderByAtypics("true")}><ArrowCircleDownTwoToneIcon className='w-full h-full' /></IconButton>):null}
                                        </div>
                                    </div>
                                </th>
                                <th><p className="mb-0 text-center text-xs xl:text-base 4xl:text-lg font-semibold">{t("policyManagement.table.columns.details")}</p></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredDataDemands.slice(0, pageDemandsSize).map((item, index) => (
                                <tr key={item.id} className={`${index % 2 === 0 ? 'bg-neutral-background' : 'bg-neutral-200'}`}>
                                    <td><p className="my-2 text-xs xl:text-base 4xl:text-lg text-center">{item.plant}</p></td>
                                    <td className='flex justify-center'><button className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center underline text-blue-500" onClick={() => openDescription(item)}>{item.material}</button></td>
                                    <td><p className="my-2 text-xs xl:text-base 4xl:text-lg text-center">{(item.avg_demand).toFixed(2)}</p></td>
                                    <td><p className="my-2 text-xs xl:text-base 4xl:text-lg text-center">{(item.std_demand).toFixed(2)}</p></td>
                                    <td><p className="my-2 text-xs xl:text-base 4xl:text-lg text-center">{item.agg_lvl}</p></td>
                                    <td>
                                        <div className='flex justify-center'>
                                            <p className="my-2 text-xs xl:text-base 4xl:text-lg">
                                                {item.has_atypics ? 
                                                    <img className='h-5 w-5 xl:h-6 xl:w-6' src={alertIcon} alt="alert-icon"/>
                                                :""}
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='flex justify-center'>
                                            <IconButton id='eye-demands' onClick={() => handleModalData(item)}><VisibilityIcon className='w-full h-full' /></IconButton>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ):(<div id="scroll-demands" className='hidden'></div>)}
        </div>
    );
}

TablePolicy.propTypes = {
    filteredPolicy: PropTypes.array, 
    filteredDemands: PropTypes.array,
    allDataDemands: PropTypes.array, 
    restartScroll: PropTypes.bool, 
    sendChecked: PropTypes.func, 
    sendCheckeds: PropTypes.func, 
    sendTextFilter: PropTypes.func, 
    sendActiveTable: PropTypes.func, 
    sendRestarScroll: PropTypes.func,
    sendLoadDemandsModal: PropTypes.func
}

export default TablePolicy;
