// Import the resources
import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../styles/negotiation_style.css';
import totalMoney from '../../../images/totalMoney.svg';
import ModalNegotiation from '../../pure/modals/modalNegotiation';
import ChartPendingCategory from '../../pure/chartPendingCategory';
import ChartPendingVendor from '../../pure/chartPendingVendor';
import Papa from 'papaparse';
import Swal from 'sweetalert2';
import { getNegotiation, getIniNegotiation, postNegotiation, postDownload } from '../../../models/negotiation_data';
import Loading from '../../pure/loading';
import { CountryComponentContext } from '../../../context/CountryComponentContext';
import { addVendorsModal, addCategoriesModal, addImputationsModal, addPrices, addChartCategories, 
    addChartVendors, addImputationSelected, addVendorsSelected, addCategoriesSelected,
    addYearSelected, addMonthSelected, addCountrySelected } from '../../../redux/spendSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addThousandPoints, formatNumberToMM } from '../../../models/generics';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';

/**
 * @component
 * @description This component show the spend tracking view
*/
const Negotiation = () => {
    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
    */
    const { t } = useTranslation();

    /**
     * @hook
     * @name dispatch
     * @type {function}
     * @description It provides the ability save and get data in the redux store
    */
    const dispatch = useDispatch()

    /**
     * @hook
     * @name negotiation
     * @type {function}
     * @description It provides the global data in the redux store
    */
    const negotiation = useSelector((state) => state.negotiation)

    /**
     * @context
     * @description Access to get the global state of the CountryComponentContext
    */
    const {country} = useContext(CountryComponentContext)

    /**
     * @hook
     * @name navigate
     * @type {function}
     * @description It provides the ability to the app with the country selected
    */
    const navigate = useNavigate()

    /**
     * @constant
     * @type {array}
     * @description Contain the months to make the post method in the modal
    */
    const months = [
        {id:"01", value:"january"}, 
        {id:"02", value:"february"},
        {id:"03", value:"march"}, 
        {id:"04", value:"april"}, 
        {id:"05", value:"may"}, 
        {id:"06", value:"june"}, 
        {id:"07", value:"july"}, 
        {id:"08", value:"august"}, 
        {id:"09", value:"september"}, 
        {id:"10", value:"october"}, 
        {id:"11", value:"november"}, 
        {id:"12", value:"december"}, 
        {id:"13", value:"all"}
    ]

    const valueMonths = months.map(item => item.value);

    const loadText = t("spendTracking.cargaText")

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local State to show the logoCarga
    */
    const [loading, setLoading] = useState(true);

    /**
     * @constant
     * @type {date}
     * @description Contain the current year
    */
    const year = new Date().getFullYear();

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local state to show modal to make the POST method
    */
    const [modal, setModal] = useState(false);

    /**
     * @function
     * @name openModal
     * @description Function to update the value of the "modal" state
    */
    const openModal = () => setModal(!modal);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local state to save the years got by the current year
    */
    const [years, setYears] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local state to save the imputation levels to use in the modal
    */
    const [imputationLevel, setImputationLevel] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local state to show the vendors and categories on the barcharts
    */
    const [vendors, setVendors] = useState([]);
    const [categories, setCategories] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local states to save the vendors and categories to use in the modal
    */
    const [vendorsModal, setVendorsModal] = useState([]);
    const [categoriesModal, setCategoriesModal] = useState([]);

    /**
     * @state
     * @type {number}
     * @default 0
     * @description Local state for the year used in the modal and show it in the superior bar
    */
    const [initialYear, setInitialYear] = useState(0);

    /**
     * @state
     * @type {object}
     * @default {}
     * @description Local state for the country used in the modal and show it in the superior bar
    */
    const [initialCountry, setInitialCountry] = useState("");

    /**
     * @state
     * @type {object}
     * @default ""
     * @description Local state for the month used in the modal and show it in the superior bar
    */
    const [initialMonth, setInitialMonth] = useState("");

    /**
     * @state
     * @type {array}
     * @default ""
     * @description Local state for the imputation levels used in the modal and show them in the superior bar
    */
    const [initialImputationLevel, setInitialImputationLevel] = useState([]);

    /**
     * @state
     * @type {array}
     * @default ""
     * @description Local state for the vendors and categories used in the modal and show them in the superior bar
    */
    const [initialVendors, setInitialVendors] = useState([]);
    const [initialCategories, setInitialCategories] = useState([]);

    /**
     * @state
     * @type {number}
     * @default 0
     * @description Local states to save the values for the boxes
    */
    const [spendTotalValue, setSpendTotalValue] = useState(0);
    const [receivedValues, setReceivedValues] = useState(0);
    const [pendingValues, setPendingValues] = useState(0);

    /**
     * @effect
     * @description This effect is executed when the data in negotiation store is updated. 
     * Update the states:
     * imputationLevel, vendorsModal, categoriesModal, valoresOrdenes, valoresIngresados, valoresPendientes
     * categories, vendors, initialImputationLevel, initialVendors, initialCategories, initial, initialMonth,
     * initialPais
    */
    useEffect(() => {
        if (negotiation.chartCategories.length > 0 && negotiation.chartVendors.length > 0) {
            setImputationLevel(negotiation.imputationsModal)
            setVendorsModal(negotiation.vendorsModal)
            setCategoriesModal(negotiation.categoriesModal)
            setSpendTotalValue(negotiation.priceSpend.pending)
            setReceivedValues(negotiation.priceSpend.received)
            setPendingValues(negotiation.priceSpend.totalSpend)
            setCategories(negotiation.chartCategories)
            setVendors(negotiation.chartVendors)
            setInitialImputationLevel(negotiation.imputationSelected)
            setInitialVendors(negotiation.vendorsSelected)
            setInitialCategories(negotiation.categoriesSelected)
            setInitialYear(negotiation.yearSelected)
            setInitialMonth(negotiation.monthSelected)
            setInitialCountry(negotiation.countrySelected)
            setLoading(false)
        }
    }, [negotiation]);

    /**
     * @effect
     * @description This effect is executed when the component is opened. 
     * Update the years state
     * If negotiation.vendorsModal or negotiation.categoriesModal quantity is less than 1, execute the 
     * get methods getIniNegotiation and getNegotiation
     * Which data execute the reducers addVendorsModal, addCategoriesModal, addImputationsModal, addPrices
     * addCategoriesGrafico, addVendorsGrafico, addVendorsEscogido, addCategoriesEscogido, addImputationEscogido,
     * addYearEscogido, addMonthEscogido and addPaisEscogido
    */
    useEffect(() => {
        const yearsArray = [year - 3, year - 2, year - 1, year];
        setYears(yearsArray)
        if (negotiation.chartCategories.length < 1 || negotiation.chartVendors.length < 1) {
            const promiseIniNego = getIniNegotiation(country)
            const promiseNego = getNegotiation(country)

            Promise.all([promiseIniNego, promiseNego])
            .then(([responseIniNego, responseNego]) => {
                dispatch(addVendorsModal(responseIniNego.vendors))
                dispatch(addCategoriesModal(responseIniNego.categories))
                dispatch(addImputationsModal(responseIniNego.imputations))
                const prices = {
                    "totalSpend": responseNego.totalPxq,
                    "received": responseNego.totalEntered,
                    "pending": responseNego.totalPending
                }
                const vendors = responseNego.vendors.map(item => item.vendor_name_standar)
                const categories = responseNego.categories.map(item => item.categoria)
                dispatch(addPrices(prices))
                dispatch(addChartCategories(responseNego.categories))
                dispatch(addChartVendors(responseNego.vendors))
                dispatch(addVendorsSelected(vendors))
                dispatch(addCategoriesSelected(categories))
                dispatch(addImputationSelected(["K","F","P","","0"]))
                dispatch(addYearSelected(year-1))
                dispatch(addMonthSelected("february"))
                dispatch(addCountrySelected(country.countryNego))
                setLoading(false)
            }).catch(error => {
                if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: t("finishedSessionTitle"),
                        text: t("finishedSessionText"),
                        confirmButtonColor: '#FFC629',
                        confirmButtonText: "LogIn",
                        allowOutsideClick: false
                    }).then(() => {
                        navigate("/")
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '¡Oops!',
                        confirmButtonColor: '#FFC629',
                        text: t("ApiFail")
                    })
                }
                setLoading(false)
            });
        }
    }, []);

    /**
     * @state
     * @type {string}
     * @default 0
     * @description Local states to save the country and year used in the modal to post method.
    */
    const [countryModal, setCountryModal] = useState("");
    const [yearModal, setYearModal] = useState("");

    /**
     * @state
     * @type {object}
     * @default 0
     * @description Local states to save the month used in the modal to post method.
    */
    const [monthsModal, setMonthsModal] = useState({});

    /**
     * @state
     * @type {array}
     * @default 0
     * @description Local states to save the imputation levels, vendors and categories used in the modal 
     * to post method.
    */
    const [imputationModal, setImputationModal] = useState([]);
    const [vendorModal, setVendorModal] = useState([]);
    const [categoryModal, setCategoryModal] = useState([]);

    /**
     * @functions
     * @name sendNewModalData
     * @description Function to update the states: paisModal, yearModal, monthsModal, imputationModal, 
     * vendorModal and categoryModal
     * @param data - Data from the modal
    */
    const sendNewModalData = (data) => {
        setCountryModal(data.countrySelected)
        setYearModal(data.yearSelected)
        setMonthsModal(data.monthsSelected)
        setImputationModal(data.impuntationSelected)
        setVendorModal(data.vendorSelected)
        setCategoryModal(data.categorySelected)
    }

    /**
     * @function
     * @name updateDataView
     * @description Function to execute the method postNegotiation with the modal data 
     * and execute the reducers addPrices, addCategoriesGrafico, addVendorsGrafico, addVendorsEscogido,
     * addCategoriesEscogido, addImputationEscogido, addYearEscogido, addMonthEscogido and addPaisEscogido
    */
    function updateDataView() {
        setLoading(true)
        const elementFound = months.find(obj => obj.value === monthsModal);
        const data = {
            "countriesSelected":countryModal,
            "yearSelected":yearModal,
            "monthsSelected":elementFound.value === "all" ? "All" : elementFound.id,
            "impuntationSelected":imputationModal,
            "vendorSelected":vendorModal,
            "categorySelected":categoryModal
        }
        postNegotiation(data, country).then((responseNego) => {
            if (responseNego.data.length < 1) {
                Swal.fire({
                    icon: 'error',
                    title: '¡Oops!',
                    confirmButtonColor: '#FFC629',
                    text: t("spendTracking.filtersFail")
                })
                setLoading(false)
            }else{
                const prices = {
                    "totalSpend": responseNego.data.valor_total_pxq_po,
                    "received": responseNego.data.valor_total_ingresado_po,
                    "pending": responseNego.data.valor_total_pend_po
                }
                const vendors = responseNego.data.vendors.filter(item =>item.valor_pendiente_usd > 0).map(item => item.vendor_name_standar)
                const categories = responseNego.data.categories.filter(item =>item.valor_pendiente_usd > 0).map(item => item.categoria)
                dispatch(addPrices(prices))
                if (responseNego.data.vendors.length > 0 || responseNego.data.vendors !== undefined) {
                    dispatch(addChartVendors(responseNego.data.vendors))
                    dispatch(addVendorsSelected(vendors))
                } else {
                    dispatch(addChartVendors([]))
                    dispatch(addVendorsSelected(vendorModal))
                }
                if (responseNego.data.categories.length > 0 || responseNego.data.categories !== undefined) {
                    dispatch(addChartCategories(responseNego.data.categories))
                    dispatch(addCategoriesSelected(categories))
                } else {
                    dispatch(addChartCategories([]))
                    dispatch(addCategoriesSelected(categoryModal))
                }
                dispatch(addImputationSelected(imputationModal))
                dispatch(addYearSelected(parseInt(yearModal)))
                dispatch(addMonthSelected(monthsModal))
                dispatch(addCountrySelected(countryModal))
                setLoading(false)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                Swal.fire({
                    icon: 'error',
                    title: t("finishedSessionTitle"),
                    text: t("finishedSessionText"),
                    confirmButtonColor: '#FFC629',
                    confirmButtonText: "LogIn",
                    allowOutsideClick: false
                }).then(() => {
                    navigate("/")
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '¡Oops!',
                    confirmButtonColor: '#FFC629',
                    text: t("ApiFail")
                })
            }
            setLoading(false)
        })
        openModal()
    }

    /**
     * @function
     * @name exportToCSV
     * @description Function to clean the negotiation data and export it into CSV for the user
     * @param data - Data to export to CSV
     * @param filename - Name of the CSV file
    */
    function exportToCSV(data, filename) {
        function removeHtmlTags(str) {
            return str.replace(/<[^>]*>?/gm, '');
        }
        const cleanedData = data.map(item => {
            const cleanedItem = {};
            for (let key in item) {
                if (typeof item[key] === 'string') {
                    cleanedItem[key] = removeHtmlTags(item[key]); // Remover etiquetas HTML
                } else {
                    cleanedItem[key] = item[key];
                }
            }
            return cleanedItem;
        });
        const csv = Papa.unparse(cleanedData);
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const a = document.createElement('a');
        a.href = csvUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    /**
     * @function
     * @name handleExportClick
     * @description Function to execute the post method postDownload and export the data result into CSV file.
    */
    function handleExportClick() {
        setLoading(true)
        const elementFound = months.find(obj => obj.value === initialMonth);
        const fileName = 'data_negotiation_planning.csv';
        const data = {
            "countriesSelected":initialCountry,
            "yearSelected":initialYear.toString(),
            "monthsSelected":elementFound.value === "all" ? "All" : elementFound.id,
            "impuntationSelected":initialImputationLevel,
            "vendorSelected":initialVendors,
            "categorySelected":initialCategories
        }
        postDownload(data).then(response => {
            const downloadData = response.data
            exportToCSV(downloadData, fileName);
            setLoading(false)
        }).catch(error => {
            if (error.response.status === 401) {
                Swal.fire({
                    icon: 'error',
                    title: t("finishedSessionTitle"),
                    text: t("finishedSessionText"),
                    confirmButtonColor: '#FFC629',
                    confirmButtonText: "LogIn",
                    allowOutsideClick: false
                }).then(() => {
                    navigate("/")
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '¡Oops!',
                    confirmButtonColor: '#FFC629',
                    text: t("ApiFail")
                })
            }
            setLoading(false)
        })
    }

    function validateImputationLevel(level) {
        let text;
        if (level === '0') {
            text = t("spendTracking.null");
        } else if (level === '') {
            text = t("spendTracking.empty");
        } else {
            text = level;
        }
        return text;
    }

    return (
        <div className="wallpaper flex flex-col w-4.5/5 h-full p-1 gap-y-1 overflow-y-auto">
            {/* Space for the modal */}
            <Dialog open={modal} classes={{paper: "!max-w-1/2 !w-1/2 xl:!max-w-2/5 xl:!max-w-2/5 3xl:!max-w-1.5/5 3xl:!w-1.5/5 4xl:!max-w-1/4 4xl:!w-1/4 !h-4.25/5 xl:!h-4.5/5 2xl:!h-3/5 3xl:!h-3.25/5 4xl:!h-2/5 modal-wallpaper-nego !rounded-lg"}}>
                <DialogTitle className='flex justify-between w-full items-center !py-1'>
                    <div className='flex justify-start w-4.75/5'>
                        <p className='mb-0 font-semibold text-neutral-200'>{t("spendTracking.modalTitle")}</p>
                    </div>
                    <div className='flex justify-center w-0.25/5'>
                        <IconButton color="inherit" onClick={openModal} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className='!p-2 !overflow-x-hidden'>
                    <ModalNegotiation 
                        countries={[country.countryNego]}
                        years={years}
                        months={valueMonths}
                        imputationLevel={imputationLevel}
                        vendors={vendorsModal}
                        categories={categoriesModal}
                        initialYear={initialYear}
                        initialMonth={initialMonth}
                        initialCountry={initialCountry}
                        initialImputationLevel={initialImputationLevel}
                        initialVendors={initialVendors}
                        initialCategories={initialCategories}
                        sendNewModalData={sendNewModalData}
                    />
                </DialogContent>
                <DialogActions className='p-2'>
                    <button onClick={updateDataView} className='border-none bg-brand-300 hover:bg-brand-200 w-0.75/5 rounded font-semibold py-2'>{t("applyButton")}</button>
                </DialogActions>
            </Dialog>

            {/* Space to show logoCarga if the local state "cargando" is true */}
            {loading ? (
                <Loading text={loadText} />
            ): null}

            {/* Space for the superior bar */}
            <div className='flex items-center flex-row gap-3 bg-neutral-500 rounded-xl 4xl:h-0.25/5 h-0.50/5 py-1 px-2'>
                {/* Space for the button to open the modal */}
                <div className='flex items-center w-0.50/5'>
                    <button onClick={openModal} className="text-sm xl:text-base w-full border-none rounded font-semibold py-2 bg-brand-200 hover:bg-brand-300">{t("spendTracking.changeButton")}</button>
                </div>
                {/* Space for year */}
                <div className='flex flex-row justify-start gap-1 items-center'>
                    <div className="flex justify-start">
                        <p className='mb-0 text-xs 2xl:text-base font-semibold text-neutral-200'>{t("spendTracking.year")}</p>
                    </div>
                    {initialYear !== 0 ? (
                        <div className='items-center flex justify-center rounded px-2 text-center bg-neutral-100 h-6 text-neutral-500 text-xs 2xl:text-base'>
                            <p className='mb-0'>{initialYear}</p>
                        </div>
                    ):null}
                </div>
                {/* Space for the month */}
                <div className='flex flex-row justify-start gap-1 items-center'>
                    <div className="flex justify-start">
                        <p className='mb-0 text-xs 2xl:text-base font-semibold text-neutral-200'>{t("spendTracking.months")}</p>
                    </div>
                    {initialMonth !== "" ? (
                        <div className='items-center flex justify-center rounded px-2 text-center bg-neutral-100 h-6 text-neutral-500 text-xs 2xl:text-base'>
                            <p className='mb-0'>{t("spendTracking."+initialMonth.toLowerCase())}</p>
                        </div>
                    ):null}
                </div>
                {/* Space for the imputation levels */}
                <div className='flex flex-row w-3.25/5 xl:w-3/4 justify-start gap-1 items-center'>
                    <div className="flex justify-start">
                        <p className='mb-0 text-xs 2xl:text-base font-semibold text-neutral-200'>{t("spendTracking.imputation")}</p>
                    </div>
                    {initialImputationLevel.length > 0 ? (
                    <div className='flex flex-wrap gap-2 h-8 overflow-y-auto container-imputations-row items-center'>
                        {initialImputationLevel.map((item) => (
                            <div key={item} className='items-center flex justify-center rounded text-center bg-neutral-100 h-6 text-neutral-500 text-xs 2xl:text-base'>
                                <p className={`mb-0 ${item === '0' || item === '' ? 'px-2' : 'px-3 xl:px-4'}`}>{validateImputationLevel(item)}</p>
                            </div>
                        ))}
                    </div>):null}
                </div>
            </div>

            {/* Space for the boxes */}
            <div className='flex flex-row justify-between gap-2 4xl:h-0.25/5 h-0.50/5'>
                <div className='flex flex-row justify-start gap-2 bg-neutral-200 w-1/3 px-2 py-2 rounded-lg'>
                    <div className='flex items-center'>
                        <div className='flex justify-center items-center w-8 h-8 xl:w-10 xl:h-10 bg-brand-300 rounded-full'>
                            <img src={totalMoney} alt="total-icon" className='w-3.5/5'/>
                        </div>
                    </div>
                    <div className='flex justify-center items-center'>
                        <p className='mb-0 text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl font-semibold'>{t("spendTracking.total")}</p>
                    </div>
                    <div className="w-1 bg-black mx-2"></div>
                    <div className='items-center flex justify-center'>
                        <div className='w-full'>
                            <p className='mb-0 font-semibold xl:text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl hidden xl:block'>$ {addThousandPoints(spendTotalValue)} USD</p>
                            <p className='mb-0 font-semibold text-sm block xl:hidden'>$ {formatNumberToMM(spendTotalValue)}</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row justify-start gap-2 bg-neutral-200 w-1/3 px-2 py-2 rounded-lg'>
                    <div className='flex items-center'>
                        <div className='flex justify-center items-center w-8 h-8 xl:w-10 xl:h-10 bg-brand-300 rounded-full'>
                            <AttachMoneyIcon className='w-3.5/5' />
                        </div>
                    </div>
                    <div className='flex justify-center items-center'>
                        <p className='mb-0 text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl font-semibold'>{t("spendTracking.received")}</p>
                    </div>
                    <div className="w-1 bg-black mx-2"></div>
                    <div className='items-center flex justify-center'>
                        <div className='w-full'>
                            <p className='mb-0 font-semibold xl:text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl hidden xl:block'>$ {addThousandPoints(receivedValues)} USD</p>
                            <p className='mb-0 font-semibold text-sm block xl:hidden'>$ {formatNumberToMM(receivedValues)}</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row justify-start gap-2 bg-neutral-200 w-1/3 px-2 py-2 rounded-lg'>
                    <div className='flex items-center'>
                        <div className='flex justify-center items-center w-8 h-8 xl:w-10 xl:h-10 bg-brand-300 rounded-full'>
                            <QuestionMarkIcon className='w-3.5/5' />
                        </div>
                    </div>
                    <div className='flex justify-center items-center'>
                        <p className='mb-0 text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl font-semibold'>{t("spendTracking.pending")}</p>
                    </div>
                    <div className="w-1 bg-black mx-2"></div>
                    <div className='items-center flex justify-center'>
                        <div className='w-full'>
                            <p className='mb-0 font-semibold xl:text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl hidden xl:block'>$ {addThousandPoints(pendingValues)} USD</p>
                            <p className='mb-0 font-semibold text-sm block xl:hidden'>$ {formatNumberToMM(pendingValues)}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Space for the barcharts */}
            <div className='flex flex-row justify-between h-3/4 xl:h-3.5/5 2xl:h-3/4 4xl:h-4.25/5 gap-1'>
                <ChartPendingCategory categories={categories}></ChartPendingCategory>
                <ChartPendingVendor vendors={vendors}></ChartPendingVendor>
            </div>

            {/* Space for the download button */}
            <div className='flex justify-end w-full h-0.25/5'>
                <button onClick={handleExportClick} className='flex justify-center items-center text-xs xl:text-sm 2xl:text-base 4xl:text-xl w-1/4 h-full border-none rounded font-semibold px-2 bg-brand-200 hover:bg-brand-300'>{t("spendTracking.downloadButton")}</button>
            </div>
        </div>
    );
}

export default Negotiation;
