import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDown from '@mui/icons-material/ArrowCircleDown';
import { createTheme, ThemeProvider, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

const theme = createTheme({
    palette: {
      brand: {
        main: '#FFC629'
      },
    },
});

const TableBlock = ({data, loadingPage, methodUsed, restartScroll, sendPage, sendRestarScroll, sendChecked, sendChekeds}) => {
    
    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
    */
    const { t } = useTranslation();

    const [dataTable, setDataTable] = useState([]);
    const [dataBackup, setDataBackup] = useState([]);
    const [allMarked, setAllMarked] = useState(false);

    const [filterText, setFilterText] = useState("");

    const [order, setOrder] = useState("");
    const [orderField, setOrderField] = useState("");

    const handleOrder = (order, data, field) => {
        const sortedData = [...data];
        sortedData.sort((a, b) => {
            if (a[field] === order && b[field] !== order) {
                return -1;
            } else if (b[field] === order && a[field] !== order) {
                return 1;
            } else {
                return 0;
            }
        });
        setOrder(order)
        setOrderField(field)
        setDataTable(sortedData);
    }

    useEffect(() => {
        if (methodUsed === "POST") {
            const scrollContainer = document.getElementById('scroll-container');
            scrollContainer.scrollTop = 0;
        }
    }, [methodUsed]);

    useEffect(() => {
        const allChecked = data.some(item => item.approved === false);
        if (allChecked) {
            setAllMarked(false)
        } else {
            setAllMarked(true)
        }
        const dataWithYes = data.map((item) => {
            let material_status_global;
            let block_proposal;
        
            if (item.stock === 0) {
                material_status_global = 'Zero Stock';
            } else if (item.stock < item.rp) {
                material_status_global = 'Stock Out';
            } else if (item.stock >= item.rp && item.stock <= item.sm) {
                material_status_global = 'Stock OK';
            } else if (item.stock > item.sm) {
                material_status_global = 'Over Stock';
            }

            if (material_status_global === 'Over Stock') {
                block_proposal = 'Block';
            } else if (material_status_global !== 'Over Stock') {
                block_proposal = 'UnBlock';
            }
        
            return {
                ...item,
                material_status_global,
                block_proposal
            };
        });
        if (order !== "" || orderField !== "") {
            handleOrder(order, dataWithYes, orderField)
        } else {
            setDataTable(dataWithYes)
        }
        setDataBackup(dataWithYes)
        sendRestarScroll(false)
    }, [data]);

    useEffect(() => {
        if (restartScroll) {
            const scrollContainer = document.getElementById('scroll-container');
            scrollContainer.scrollTop = 0; 
        }
    }, [restartScroll]);

    const handleCheckboxChange = (element) => {
        element.approved = !element.approved
        sendChecked(element)
    };

    const handleAllCheckbox = () => {
        const newArray = data.map((item) => ({...item, approved: !allMarked}));
        sendChekeds(newArray)
    }

    const handleRestoreOrder = () => {
        setOrder("")
        setDataTable(dataBackup)
    }

    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
    }

    const filteredData = [...dataTable].sort((a, b) => {
        const aIncludes = a.material.includes(filterText);
        const bIncludes = b.material.includes(filterText);
    
        if (aIncludes && !bIncludes) {
            return -1;
        } else if (!aIncludes && bIncludes) {
            return 1;
        } else {
            return 0;
        }
    });

    const handleScroll = () => {
        const scrollContainer = document.getElementById('scroll-container');
        if (scrollContainer) {
          const scrollTop = scrollContainer.scrollTop;
          const scrollHeight = scrollContainer.scrollHeight;
          const clientHeight = scrollContainer.clientHeight;
          if (scrollHeight - scrollTop - clientHeight <= 1) {
            sendPage(1)
          }
        }
    };

    useEffect(() => {
        const scrollContainer = document.getElementById('scroll-container');
        if (scrollContainer) {
          scrollContainer.addEventListener('scroll', handleScroll);
        }
    
        return () => {
          if (scrollContainer) {
            scrollContainer.removeEventListener('scroll', handleScroll);
          }
        };
    }, [dataTable]);

  return (
    <div className='flex flex-col h-full gap-y-2'>
        <div className="flex flex-row items-center h-0.50/5 3xl:h-0.25/5">
            <div className="relative w-4.5/5 xl:w-4.75/5 flex items-center">
                <SearchIcon className='left-3 absolute' />
                <input onChange={handleFilterChange} type="text" className="py-2 ps-10 pe-2 rounded-lg w-full border-2 border-solid focus:outline-none focus:border-focused placeholder:font-semibold" placeholder={t("policyManagement.table.placeholder")} />
            </div>
            <div className='w-0.50/5 xl:w-0.25/5 flex justify-center'>
                <button onClick={() => handleRestoreOrder()} className='flex justify-center items-center bg-brand-300 hover:bg-brand-200 h-10 w-10 border-none rounded-full'>
                    <ReplayIcon />
                </button>
            </div>
        </div>
        <div id="scroll-container" className={`h-4.5/5 3xl:h-4.75/5 w-full ${loadingPage === false ? 'overflow-y-auto' : 'overflow-y-hidden'} `}>
            <table className='w-full'>
                <thead className='bg-neutral-background z-50 sticky top-0'>
                    <tr>
                        <th><p className="mb-0 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-semibold">{t("block.table.plant")}</p></th>
                        <th><p className="mb-0 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-semibold">Material</p></th>
                        <th><p className="mb-0 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-semibold">{t("block.table.reorderPoint")}</p></th>
                        <th><p className="mb-0 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-semibold" dangerouslySetInnerHTML={{ __html: t("block.table.stockMaxActual") }}></p></th>
                        <th><p className="mb-0 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-semibold" dangerouslySetInnerHTML={{ __html: t("block.table.stockMaxProposal") }}></p></th>
                        <th>
                            <div className='items-center flex flex-col'>
                                <div className='flex justify-center'>
                                    <p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold" dangerouslySetInnerHTML={{ __html: t("block.table.materialStatusGlobal") }}></p>
                                </div>
                                <div className='flex flex-row gap-1'>
                                    <div>
                                        <Tooltip title={t("block.tooltips.zeroStock")} arrow placement="top">
                                            <IconButton disabled={order === "Zero Stock"} className='!p-0' onClick={() => handleOrder("Zero Stock", dataBackup, "material_status_global")}>
                                                <ArrowCircleUpIcon className='w-full h-full' />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Tooltip title={t("block.tooltips.stockOut")} arrow placement="top">
                                            <IconButton disabled={order === "Stock Out"}  className='!p-0' onClick={() => handleOrder("Stock Out", dataBackup, "material_status_global")}>
                                                <ArrowCircleUpIcon className='w-full h-full' />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Tooltip title={t("block.tooltips.stockOk")} arrow placement="top">
                                            <IconButton disabled={order === "Stock OK"}  className='!p-0' onClick={() => handleOrder("Stock OK", dataBackup, "material_status_global")}>
                                                <ArrowCircleUpIcon className='w-full h-full' />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Tooltip title={t("block.tooltips.overStock")} arrow placement="top">
                                            <IconButton disabled={order === "Over Stock"} className='!p-0' onClick={() => handleOrder("Over Stock", dataBackup, "material_status_global")}>
                                                <ArrowCircleUpIcon className='w-full h-full' />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className='items-center flex flex-row justify-center'>
                                <div className='flex justify-center'>
                                    <p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold" dangerouslySetInnerHTML={{ __html: t("block.table.blockUnblock") }}></p>
                                </div>
                                <div className='flex items-center h-full'>
                                    {order === "UnBlock" ? (
                                        <IconButton className='!p-0' onClick={() => handleOrder("Block", dataBackup, "block_proposal")}>
                                            <ArrowCircleDown className='w-full h-full' /></IconButton>):(
                                        <IconButton className='!p-0' onClick={() => handleOrder("UnBlock", dataBackup, "block_proposal")}>
                                            <ArrowCircleUpIcon className='w-full h-full' /></IconButton>
                                    )}
                                </div>
                            </div>
                        </th>
                        <th><p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold" dangerouslySetInnerHTML={{ __html: t("block.table.materialStatusPlant") }}></p></th>
                        <th><p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold" dangerouslySetInnerHTML={{ __html: t("block.table.statusValidateDate") }}></p></th>
                        <th>
                            <div className='flex flex-col gap-y-1'>
                                <div className='flex justify-center w-full'>
                                    <p className="mb-0 text-center text-xs xl:text-sm 2xl:text-base 4xl:text-lg font-semibold">{t("block.table.approveProposal")}</p>
                                </div>
                                <div className='flex justify-center w-full'>
                                    <div>
                                        <input type='checkbox' className="w-4 h-4 2xl:w-5 2xl:h-5" onChange={handleAllCheckbox} checked={allMarked} />
                                    </div>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item, index) => (
                        <tr key={item.id} className={`${index % 2 === 0 ? 'bg-neutral-background' : 'bg-neutral-200'}`}>
                            <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{item.plant}</p></td>
                            <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{item.material}</p></td>
                            <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{item.rp}</p></td>
                            <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{item.sm}</p></td>
                            <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{item.ss}</p></td>
                            <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{item.material_status_global}</p></td>
                            <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{item.block_proposal}</p></td>
                            <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{item.plant_status}</p></td>
                            <td><p className="my-2 text-xs xl:text-sm 2xl:text-base 4xl:text-lg text-center">{item.status_valid_date}</p></td>
                            <td>
                                <div className='flex justify-center w-full'>
                                    <input type='checkbox' className="w-4 h-4 2xl:w-5 2xl:h-5" onChange={() => handleCheckboxChange(item)}  checked={item.approved === true} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        {loadingPage ? (
            <div className='flex justify-center w-full'>
                <ThemeProvider theme={theme}>
                    <CircularProgress color="brand" />
                </ThemeProvider>
            </div>
        ) : null}
        
    </div>
  )
}

TableBlock.propTypes = {
    data: PropTypes.array, 
    loadingPage: PropTypes.bool, 
    methodUsed: PropTypes.string,
    restartScroll: PropTypes.bool,
    sendPage: PropTypes.func,
    sendRestarScroll: PropTypes.func,
    sendChecked: PropTypes.func, 
    sendChekeds: PropTypes.func
}

export default TableBlock