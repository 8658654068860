import { createContext, useState, useMemo } from "react";
import PropTypes from 'prop-types';

export const PermissionContext = createContext()

/**
 * @context
 * @description This context contains the user's permissions
*/
const PermissionContextProvider = ({children}) => {

  /**
   * @state
   * @type {array}
   * @default []
   * @description State to save the user's permissions
  */
  const [permissions, setPermissions] = useState([]);

  /**
   * @constant
   * @description Object that holds the current state of permissions and a function to update it
  */
  const data = useMemo(() => ({
    permissions, 
    setPermissions
  }), [permissions]);

  return (
    <PermissionContext.Provider value={data}>
        {children}
    </PermissionContext.Provider>
  )
}

PermissionContextProvider.propTypes = {
  children: PropTypes.node,
};

export default PermissionContextProvider