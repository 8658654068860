import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    materialWrote: "",
    plants: [],
    stockHistory:[],
    essentialInfo: [],
    demands:[],
    plantSelected: ""
}

export const materialSlice = createSlice({
    name: 'materials',
    initialState,
    reducers: {
        /**
         * @function addMaterial
         * @description A reducer to update materialWrote.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addMaterial: (state, action) => {
            state.materialWrote = action.payload;
        },

        /**
         * @function addPlants
         * @description A reducer to update plants.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addPlants: (state, action) => {
            state.plants = action.payload;
        },

        /**
         * @function addStockHistory
         * @description A reducer to update stockHistory.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addStockHistory: (state, action) => {
            state.stockHistory = action.payload;
        },

        /**
         * @function addEssentialInfo
         * @description A reducer to update essentialInfo.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addEssentialInfo: (state, action) => {
            state.essentialInfo = action.payload;
        },

        /**
         * @function addDemands
         * @description A reducer to update demands.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addDemands: (state, action) => {
            state.demands = action.payload;
        },

        /**
         * @function addPlantSelected
         * @description A reducer to update plantSelected.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addPlantSelected: (state, action) => {
            state.plantSelected = action.payload;
        },

        /**
         * @function resetStateMaterial
         * @description A reducer to reset the states
        */
        resetStateMaterial: () => initialState
    }
})

export const { addMaterial, addPlants, addStockHistory, addEssentialInfo, addDemands, addPlantSelected, resetStateMaterial } = materialSlice.actions;
export default materialSlice.reducer;