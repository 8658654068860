// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @description Styles for the scrollbar when this is neccesary on the policy row on the superior bar
 */
 .planner_row_block::-webkit-scrollbar {
    width:10px;
    background-color: #f1f1f1;
    border-radius: 10px;
}

.planner_row_block::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    width: 1px;
}

.planner_row_block::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/**
 * @description Background color of the modal
 */
 .modal-block-font {
    background-color: #616161 !important;
}

/**
 * @description Style for the scrollbar of the modal
*/
.height-modal-config::-webkit-scrollbar {
    width:10px;
    background-color: #f1f1f1;
    border-radius: 10px;
}

.height-modal-config::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    width: 1px;
}

.height-modal-config::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}`, "",{"version":3,"sources":["webpack://./src/styles/block_style.css"],"names":[],"mappings":"AAAA;;EAEE;CACD;IACG,UAAU;IACV,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;;EAEE;CACD;IACG,oCAAoC;AACxC;;AAEA;;CAEC;AACD;IACI,UAAU;IACV,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":["/**\n * @description Styles for the scrollbar when this is neccesary on the policy row on the superior bar\n */\n .planner_row_block::-webkit-scrollbar {\n    width:10px;\n    background-color: #f1f1f1;\n    border-radius: 10px;\n}\n\n.planner_row_block::-webkit-scrollbar-thumb {\n    background-color: #888;\n    border-radius: 4px;\n    width: 1px;\n}\n\n.planner_row_block::-webkit-scrollbar-thumb:hover {\n    background-color: #555;\n}\n\n/**\n * @description Background color of the modal\n */\n .modal-block-font {\n    background-color: #616161 !important;\n}\n\n/**\n * @description Style for the scrollbar of the modal\n*/\n.height-modal-config::-webkit-scrollbar {\n    width:10px;\n    background-color: #f1f1f1;\n    border-radius: 10px;\n}\n\n.height-modal-config::-webkit-scrollbar-thumb {\n    background-color: #888;\n    border-radius: 4px;\n    width: 1px;\n}\n\n.height-modal-config::-webkit-scrollbar-thumb:hover {\n    background-color: #555;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
