import React, {useContext} from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { RoleContext } from '../../../context/RoleContext';


const ProtectedUser = () => {
  
    /**
     * @context
     * @description Access to get the global state of the RoleContext
    */
    const {role} = useContext(RoleContext)
  
    /**
     * @condition
     * @description Validate if the permissions object has a block key with true value to enroute to block view
    */
    if (role !== "super_admin") {
      return <Navigate to="/inventory/summary" />
    }
  
    return (
      <Outlet />
    )
  }
  
  export default ProtectedUser