/**
 * @function
 * @name formatNumberToMM
 * @description Function to format a number
 * @param {number} number - Number specified
*/
function formatNumberToMM(number) {
    if (typeof number !== 'number') {
      return '0';
    }
    
    const mil = 1000;
    const millon = 1000000;
    const milMillon = 1000000000;

    if (number >= milMillon) {
        const formattedNumber = (number / milMillon).toFixed(2);
        return `${formattedNumber} MM`;
    }

    if (number >= millon) {
        const formattedNumber = (number / millon).toFixed(2);
        return `${formattedNumber} M`;
    }

    if (number >= mil) {
        const formattedNumber = (number / mil).toFixed(2);
        return `${formattedNumber} K`;
    }
  
    return `${number.toFixed(2)}`;
}

function addThousandPoints(number) {
    let isNegative = number < 0;
    let num = Math.abs(number).toFixed(2);
    let [integer, decimal] = num.split(".");
    let result = '';
    while (integer.length > 3) {
        result = ',' + integer.slice(-3) + result;
        integer = integer.slice(0, integer.length - 3);
    }
    result = integer + result;
    if (isNegative) {
        result = '-' + result;
    }
    return Number.isInteger(number) ? result : result + '.' + decimal;
}

export { formatNumberToMM, addThousandPoints };
