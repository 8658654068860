// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar-element-user::-webkit-scrollbar {
    width:10px;
    background-color: #f1f1f1;
    border-radius: 10px;
}

.bar-element-user::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    width: 1px;
}

.bar-element-user::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}


#input-first.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input,
#input-second.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 8.5px 14px;
    text-transform: capitalize;
}

`, "",{"version":3,"sources":["webpack://./src/styles/user_style.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,sBAAsB;AAC1B;;;AAGA;;IAEI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,uBAAuB;IACvB,gBAAgB;IAChB,gBAAgB;IAChB,SAAS;IACT,wCAAwC;IACxC,cAAc;IACd,YAAY;IACZ,WAAW;IAEX,oCAAoC;IAEpC,wBAAwB;IACxB,mBAAmB;IACnB,0BAA0B;AAC9B","sourcesContent":[".bar-element-user::-webkit-scrollbar {\n    width:10px;\n    background-color: #f1f1f1;\n    border-radius: 10px;\n}\n\n.bar-element-user::-webkit-scrollbar-thumb {\n    background-color: #888;\n    border-radius: 4px;\n    width: 1px;\n}\n\n.bar-element-user::-webkit-scrollbar-thumb:hover {\n    background-color: #555;\n}\n\n\n#input-first.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input,\n#input-second.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {\n    font: inherit;\n    letter-spacing: inherit;\n    color: currentColor;\n    border: 0;\n    box-sizing: content-box;\n    background: none;\n    height: 1.4375em;\n    margin: 0;\n    -webkit-tap-highlight-color: transparent;\n    display: block;\n    min-width: 0;\n    width: 100%;\n    -webkit-animation-name: mui-auto-fill-cancel;\n    animation-name: mui-auto-fill-cancel;\n    -webkit-animation-duration: 10ms;\n    animation-duration: 10ms;\n    padding: 8.5px 14px;\n    text-transform: capitalize;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
