// Import the resources
import React, {useState, useEffect} from 'react';
import '../../styles/policy_style.css';
import { useTranslation } from 'react-i18next';
import LabelPolicy from './labelsCharts/labelPolicy';
import LegendPolicy from './legendsCharts/legendPolicy';
import { BarChart, Bar, Legend, ResponsiveContainer, LabelList } from 'recharts';
import PropTypes from 'prop-types';

/**
 * @component
 * @description This component show the charts in the policy management view
*/
const ChartPolicy = ({reorderPointOne, reorderPointTwo, stockMaxOne, stockMaxTwo, totalOne, totalTwo}) => {
    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
    */
    const { t } = useTranslation();

    /**
     * @state
     * @type {boolean}
     * @default []
     * @description Local States to show the reorder point chart or stock maximo chart o 
     * resume chart according to the value
    */
    const [reorder, setReorder] = useState(true);
    const [stockMax, setStockMax] = useState(false);
    const [resume, setResume] = useState(false);

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        let data = []
        if (reorder) {
            data = [
                {
                    current: t("policyManagement.grafica.columns.current"),
                    new: t("policyManagement.grafica.columns.new"),
                    valueCurrent: reorderPointOne.toFixed(0),
                    valueNew: reorderPointTwo.toFixed(0)
                }
            ]
        } else if (stockMax) {
            data = [
                {
                    current: t("policyManagement.grafica.columns.current"),
                    new: t("policyManagement.grafica.columns.new"),
                    valueCurrent: stockMaxOne.toFixed(0),
                    valueNew: stockMaxTwo.toFixed(0)
                }
            ]
        } else if (resume) {
            data = [
                {
                    current: t("policyManagement.grafica.columns.current"),
                    new: t("policyManagement.grafica.columns.new"),
                    valueCurrent: totalOne.toFixed(0),
                    valueNew: totalTwo.toFixed(0)
                }
            ]
        }
        setChartData(data)
    }, [reorder, stockMax, resume, reorderPointOne, reorderPointTwo, stockMaxOne, stockMaxTwo, totalOne, totalTwo]);

    const legends = [
        { value: t("policyManagement.grafica.columns.current"), type: 'square', id: 'ID01', color: '#FFC629' },
        { value: t("policyManagement.grafica.columns.new"), type: 'square', id: 'ID02', color: '#BFBFBF' },
    ]

    /**
     * @function
     * @name handleReorder
     * @description Show the reorder point chart
    */
    function handleReorder() {
        setReorder(true)
        setStockMax(false)
        setResume(false)
    }

    /**
     * @function
     * @name handleStockMax
     * @description Show the stock maximo chart
    */
    function handleStockMax() {
        setReorder(false)
        setStockMax(true)
        setResume(false)
    }

    /**
     * @function
     * @name handleResume
     * @description Show the resume chart
    */
    function handleResume() {
        setReorder(false)
        setStockMax(false)
        setResume(true)
    }

    return (
        <div className='h-4.75/5'>
            <div className='rounded-t-lg flex justify-center h-0.25/5 bg-neutral-300 items-center'>
                <p className='py-2 mb-0 font-semibold text-center text-xs 2xl:text-base'>{t("policyManagement.grafica.title")}</p>
            </div>
            <div className='flex flex-col gap-y-1 py-1 h-4.75/5 bg-neutral-background rounded-lg'>
                {/* Space of the buttons to change the chart */}
                <div className='items-center flex flex-row justify-center w-full gap-2 h-0.50/5 2xl:h-0.25/5 px-1'>
                    <button onClick={handleReorder} className={`${reorder ? 'bg-brand-300' : 'bg-brand-200'} flex items-center justify-center text-xs xl:text-sm 4xl:text-base hover:bg-brand-300 h-full w-1/3 border-none rounded font-semibold 2xl:py-1`}>{t("policyManagement.grafica.reorderButton")}</button>
                    <button onClick={handleStockMax} className={`${stockMax ? 'bg-brand-300' : 'bg-brand-200'} flex items-center justify-center text-xs xl:text-sm 4xl:text-base hover:bg-brand-300 h-full w-1/3 border-none rounded font-semibold 2xl:py-1`}>{t("policyManagement.grafica.stockButton")}</button>
                    <button onClick={handleResume} className={`${resume ? 'bg-brand-300' : 'bg-brand-200'} flex items-center justify-center text-xs xl:text-sm 4xl:text-base hover:bg-brand-300 w-1/3 h-full border-none rounded font-semibold 2xl:py-1`}>{t("policyManagement.grafica.targetButton")}</button>
                </div>
                {/* Space for the chart selected */}
                <div className='h-4.5/5 2xl:h-4.75/5'>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart width={150} height={40} data={chartData} barGap={10}>
                            <Legend verticalAlign="top" payload={legends} content={<LegendPolicy />} />
                            <Bar dataKey="valueCurrent" fill="#FFC629">
                                {reorderPointOne > 0 ? <LabelList dataKey="valueCurrent" position="center" content={LabelPolicy} /> : null }
                            </Bar>
                            <Bar dataKey="valueNew" fill="#BFBFBF">
                                {reorderPointTwo > 0 ? <LabelList dataKey="valueNew" position="center" content={LabelPolicy} /> : null }
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
}

ChartPolicy.propTypes = {
    reorderPointOne: PropTypes.number, 
    reorderPointTwo: PropTypes.number, 
    stockMaxOne: PropTypes.number, 
    stockMaxTwo: PropTypes.number, 
    totalOne: PropTypes.number, 
    totalTwo: PropTypes.number
}

export default ChartPolicy;
