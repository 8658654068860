// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxes-row > div {
    flex: 1 0 20%; /* Cambia el 20% por el tamaño que desees */
}

.boxes-row::-webkit-scrollbar {
    width:5px;
    background-color: #f1f1f1;
    border-radius: 10px;
}

.boxes-row::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    width: 1px;
}

.boxes-row::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}`, "",{"version":3,"sources":["webpack://./src/styles/material_style.css"],"names":[],"mappings":"AAAA;IACI,aAAa,EAAE,2CAA2C;AAC9D;;AAEA;IACI,SAAS;IACT,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".boxes-row > div {\n    flex: 1 0 20%; /* Cambia el 20% por el tamaño que desees */\n}\n\n.boxes-row::-webkit-scrollbar {\n    width:5px;\n    background-color: #f1f1f1;\n    border-radius: 10px;\n}\n\n.boxes-row::-webkit-scrollbar-thumb {\n    background-color: #888;\n    border-radius: 4px;\n    width: 1px;\n}\n\n.boxes-row::-webkit-scrollbar-thumb:hover {\n    background-color: #555;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
