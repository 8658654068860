import { createSlice } from "@reduxjs/toolkit";

/**
 * @constant
 * @type {object}
 * @property {Array} sales - An array to store sales.
 * @property {Array} originPlanners - An array to store origin planners.
 * @property {Array} destinyPlanners - An array to store destiny planners.
 * @property {Array} destinyPlants - An array to store destiny plants.
*/
const initialState = {
    plants:[],
    plantSelected: {},
    intercompanies:[],
    intercompaniesSelected:[]
}

export const saleSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {
        addPlants: (state, action) => {
            state.plants = action.payload;
        },
        addPlantSelected: (state, action) => {
            state.plantSelected = action.payload;
        },
        addIntercompanies: (state, action) => {
            state.intercompanies = action.payload;
        },
        addIntercompaniesSelected: (state, action) => {
            state.intercompaniesSelected = action.payload;
        },

        /**
         * @function resetStateSales
         * @description A reducer to reset the states
        */
        resetStateSales: () => initialState
    }
})

export const { addPlants, addPlantSelected, addIntercompanies, addIntercompaniesSelected, resetStateSales } = saleSlice.actions;
export default saleSlice.reducer;