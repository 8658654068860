import { createSlice } from "@reduxjs/toolkit";

/**
 * @constant
 * @type {object}
 * @property {Array} filterPlanners - An array to store the filter planners.
 * @property {Array} filterPlants - An array to store the filter plants.
 * @property {Array} policies - An array to store policies.
 * @property {Array} planners - An array to store planners.
 * @property {Array} materialsTypes - An array to store materialsTypes.
 * @property {Array} demands - An array to store demands.
 * @property {Array} levels - An array to store levels.
 * @property {number} serviceLevel - A number to store the service level.
 * @property {number} monthsMaximum - A number to store the maximum months.
 * @property {string} monthsHistory - A string to store the months history.
 * @property {string} leadtime - A string to store the leadtime.
 * @property {boolean} exclude - A boolean to store the exclude state.
*/
const initialState = {
    filterPlanners: [],
    filterPlants: [],
    policies:[],
    material: "",
    planners:[],
    materialsTypes:[],
    demands:[],
    serviceLevel: 0.95,
    monthsMaximum: 2,
    monthsHistory: "12M",
    leadtime: "Business Rule",
    exclude: true
}

export const policySlice = createSlice({
    name: 'policies',
    initialState,
    reducers: {
        /**
         * @function addFilterPlanners
         * @description A reducer to update filterPlanners.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addFilterPlanners: (state, action) => {
            state.filterPlanners = action.payload;
        },

        /**
         * @function addFilterPlants
         * @description A reducer to update filterPlants.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addFilterPlants: (state, action) => {
            state.filterPlants = action.payload;
        },

        /**
         * @function addPolicies
         * @description A reducer to update policies.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addPolicies: (state, action) => {
            state.policies = action.payload;
        },

        /**
         * @function addMaterial
         * @description A reducer to update material.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addMaterial: (state, action) => {
            state.material = action.payload;
        },

        /**
         * @function addPlanners
         * @description A reducer to update planners.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addPlanners: (state, action) => {
            state.planners = action.payload;
        },
        /**
         * @function addMaterialsTypes
         * @description A reducer to update materialsTypes.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addMaterialsTypes: (state, action) => {
            state.materialsTypes = action.payload;
        },

        /**
         * @function addDemands
         * @description A reducer to update demands.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addDemands: (state, action) => {
            state.demands = action.payload;
        },

        /**
         * @function addLevels
         * @description A reducer to update serviceLevel.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addServiceLevel: (state, action) => {
            state.serviceLevel = action.payload;
        },

        /**
         * @function addMonthsMaximum
         * @description A reducer to update monthsMaximum.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addMonthsMaximum: (state, action) => {
            state.monthsMaximum = action.payload;
        },

        /**
         * @function addmonthsHistory
         * @description A reducer to update monthsHistory.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addmonthsHistory: (state, action) => {
            state.monthsHistory = action.payload;
        },

        /**
         * @function addLeadtime
         * @description A reducer to update leadtime.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addLeadtime: (state, action) => {
            state.leadtime = action.payload;
        },

        /**
         * @function addExclude
         * @description A reducer to update exclude.
         * @param {Object} state - The current state.
         * @param {Object} action - The dispatched action.
        */
        addExclude: (state, action) => {
            state.exclude = action.payload;
        },

        /**
         * @function resetStatePolicy
         * @description A reducer to reset the states
        */
        resetStatePolicy: () => initialState
    }
})

export const { addFilterPlanners, addFilterPlants, addPolicies, addMaterial, addPlanners, addMaterialsTypes, addDemands, addServiceLevel, addMonthsMaximum, addmonthsHistory, addLeadtime, addExclude, resetStatePolicy } = policySlice.actions;
export default policySlice.reducer;