import React, {useState, useEffect} from 'react'
import logoCarga from '../../images/loadLogo.gif'
import PropTypes from 'prop-types';

const Loading = ({text}) => {

  /**
   * @state
   * @type {string}
   * @default ""
   * @description Local states to update constantly the dots to show in the text while a view is loading
  */
  const [dots, setDots] = useState("");

  /**
   * @effect
   * @description This effect is executed when the component is opened
   * This effect update the dots constantly
  */
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="overlay">
      <div className='flex flex-col h-full justify-center'>
        {/* Space for the loading logo */}
        <div className='flex flex-row w-full justify-center'>
          <img src={logoCarga} alt='Carga Logo' className='load-logo' />
        </div>
        {/* Space for the text of the view loading */}
        {text ? (
          <div className='flex flex-row w-full justify-center'>
            <p className='mb-0 text-white text-lg'>{text}</p>
            <p className='mb-0 text-white text-lg'>{dots}</p>
          </div>
        ): null}
      </div>
    </div>
  )
}

Loading.propTypes = {
  text: PropTypes.string
}

export default Loading
