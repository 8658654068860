import { createContext, useState, useMemo } from "react";
import PropTypes from 'prop-types';

export const CountriesContext = createContext()

/**
 * @context
 * @description This context contains the user's countries
*/
const CountriesContextProvider = ({children}) => {

  /**
   * @state
   * @type {array}
   * @default []
   * @description State to save the user's countries
  */
  const [countries, setCountries] = useState([]);

  /**
   * @memo
   * @description Create an object that contains the current state of 'countries' and a function to 
   * update it ('setCountries').
   * This object is passed as a value to the context provider to allow access and modification of the 
   * state of countries from child components.
  */
  const data = useMemo(() => ({
    countries,
    setCountries
  }), [countries, setCountries]);

  return (
    <CountriesContext.Provider value={data}>
        {children}
    </CountriesContext.Provider>
  )
}

CountriesContextProvider.propTypes = {
  children: PropTypes.node
}

export default CountriesContextProvider
