import React from 'react'
import { addThousandPoints } from '../../../models/generics';
import PropTypes from 'prop-types';

const LabelDemands = (props) => {

    const { x, y, value, height, width  } = props;

    return (
        <text x={x + width / 2} y={y + height / 2} className='text-black font-semibold text-xs 4xl:text-lg' textAnchor="middle" dominantBaseline="middle">
            {addThousandPoints(value)}
        </text>
    )
}

LabelDemands.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    value: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
}

export default LabelDemands
