import React, {useState, useEffect, useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';
import Loading from '../../pure/loading';
import TableIntercompany from '../../pure/tableIntercompany';
import ModalRules from '../../pure/modals/modalRules';
import Swal from 'sweetalert2';
import { CountryComponentContext } from '../../../context/CountryComponentContext';
import { ZoneContext } from '../../../context/ZoneContext';
import { getIntercompanyPlants, postIntercompanyByPlant } from '../../../models/intercompany_data';
import { addThousandPoints } from '../../../models/generics';
import { addPlants, addPlantSelected, addIntercompanies, addIntercompaniesSelected } from '../../../redux/saleSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

const Intercompany = () => {

    const { t } = useTranslation();

    /**
     * @context
     * @description Access to get the global state of the CountryComponentContext
    */
    const {country} = useContext(CountryComponentContext)

    /**
     * @context
     * @description Access to get the global state of the ZoneContext
    */
    const {zone} = useContext(ZoneContext)

    const dispatch = useDispatch()

    const sales = useSelector((state) => state.sale)

    /**
     * @const
     * @description Const to save the text to show while the view is loading
    */
    const loadingText = t("intercompanySales.cargaText")

    /**
     * @hook
     * @name navigate
     * @type {function}
     * @description It provides the ability to the app with the country selected
    */
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true);
    
    const [selected, setSelected] = useState("all");

    const [dataPlants, setDataPlants] = useState([]);
    const [dataComplete, setDataComplete] = useState([]);
    const [dataSelected, setDataSelected] = useState([]);

    const [plantSelected, setPlantSelected] = useState("");
    const [buttonsHeight, setButtonsHeight] = useState("");

    const [fullPotential, setFullPotential] = useState(0);
    const [fullPotentialSelected, setFullPotentialSelected] = useState(0);
    const [totalSelected, setTotalSelected] = useState(0);

    const [modal, setModal] = useState(false);

    function updatePotential(array) {
        const fullPotential = array.reduce((acumulator, item) => {
            if (item.selected) {
                return acumulator + item.unit_price;
            } else {
                return acumulator;
            }
        }, 0);
        setFullPotentialSelected(fullPotential)
    }

    function updateTotal(array) {
        const fullPotential = array.reduce((acumulator, item) => {
            return acumulator + item.unit_price;
        }, 0);
        setTotalSelected(fullPotential)
    }

    useEffect(() => {
        setDataPlants(sales.plants)
        if (sales.plants.length > 12) {
            setButtonsHeight("h-full")
        } else {
            setButtonsHeight("h-1/2")
        }
        setPlantSelected(sales.plantSelected)
        setDataComplete(sales.intercompanies)
        setDataSelected(sales.intercompaniesSelected);
        const fullPotential = sales.intercompanies.reduce((acumulador, item) => {
            return acumulador + item.unit_price;
        }, 0);
        setFullPotential(fullPotential)
        updatePotential(sales.intercompanies)
        updateTotal(sales.intercompaniesSelected)
    }, [sales]);

    const updateArray = (dataChecked) => {
        return dataChecked.map(item1 => {
            const matchingItem = dataSelected.find(item2 => item2.id === item1.id && item2.delivery_plant === item1.delivery_plant);
            return matchingItem || item1;
        });
    }

    const selectPlant = (plant) => {
        setLoading(true)
        setSelected("all")
        dispatch(addPlantSelected(plant))
        const data = {
            "plant": plant.id
        }
        postIntercompanyByPlant(data).then((intercompanies) => {
            const dataChecked = intercompanies.data.map(item => (
                { ...item, selected: false }
            ));
            
            const updatedArray = updateArray(dataChecked);
            dispatch(addIntercompanies(updatedArray));
            setLoading(false)
        }).catch(error => {
            if (error.response.status === 401) {
                Swal.fire({
                    icon: 'error',
                    title: t("finishedSessionTitle"),
                    text: t("finishedSessionText"),
                    confirmButtonColor: '#FFC629',
                    confirmButtonText: "LogIn",
                    allowOutsideClick: false
                }).then(() => {
                    navigate("/")
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '¡Oops!',
                    confirmButtonColor: '#FFC629',
                    text: t("ApiFail")
                })
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        if (sales.plants.length < 1) {
            getIntercompanyPlants(country).then((plants) => {
                dispatch(addPlants(plants));
                selectPlant(plants[0])
            }).catch(error => {
                if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: t("finishedSessionTitle"),
                        text: t("finishedSessionText"),
                        confirmButtonColor: '#FFC629',
                        confirmButtonText: "LogIn",
                        allowOutsideClick: false
                    }).then(() => {
                        navigate("/")
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '¡Oops!',
                        confirmButtonColor: '#FFC629',
                        text: t("ApiFail")
                    })
                }
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, []);

    const sendChecked = (data) => {
        const arrayToSave = dataComplete.map((originalItem) => {
            if (originalItem.id === data.id) {
                return { ...originalItem, ...data }
            }
            return originalItem
        })
        dispatch(addIntercompanies(arrayToSave));
        let newArray = [...dataSelected];
        if (data.selected === true) {
            newArray.push(data);
        } else {
            newArray = newArray.filter(item => item.id !== data.id);
        }
        newArray.sort((a, b) => {
            if (a['delivery_plant'] < b['delivery_plant']) {
                return -1;
            } else {
                return 1;
            }
        })
        dispatch(addIntercompaniesSelected(newArray))
    }

    const sendCheckeds = (data) => {
        const updatedArray = dataComplete.map(item1 => {
            const matchingItem = data.find(item2 => item2.id === item1.id);
            if (matchingItem) {
              return matchingItem;
            }
            return item1;
        });
        dispatch(addIntercompanies(updatedArray));
        let newArray = [...dataSelected];
        data.forEach(newItem => {
            const index = newArray.findIndex(item => item.id === newItem.id);
        
            if (newItem.selected) {
              if (index !== -1) {
                newArray[index] = newItem;
              } else {
                newArray.push(newItem);
              }
            } else {
                index !== -1 && newArray.splice(index, 1);
            }
        });
        newArray.sort((a, b) => {
            if (a['delivery_plant'] < b['delivery_plant']) {
                return -1;
            } else {
                return 1;
            }
        })
        dispatch(addIntercompaniesSelected(newArray))
    }

    function exportToCSV(data, filename) {
        const convertedData = data.map((item) => {
            const copy = {...item};
            copy.unit_price = "$ " + (copy.unit_price).toFixed(2) + " USD"
            copy.purchase_value = "$ " + (copy.purchase_value).toFixed(2) + " USD"
            if (copy.provider_criticality !== null) {
                copy.provider_criticality = "Yes"
            }else {
                copy.provider_criticality = "No"
            }
            if ("id" in copy) {
                delete copy.id;
            }
            if ("selected" in copy) {
                delete copy.selected;
            }
            const selling_plant = copy.delivery_plant;
            delete copy.delivery_plant;
            return {
                selling_plant: selling_plant,
                material: copy.material,
                description: copy.description,
                stock: copy.stock,
                missing_stock: copy.missing_stock,
                purchase_plant: copy.purchase_plant,
                ...copy
            };
        });
        const csv = Papa.unparse(convertedData);
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const a = document.createElement('a');
        a.href = csvUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    function handleExportClick() {
        const fileName = 'data_intercompany_sales.csv';
        exportToCSV(dataSelected, fileName);
    }
    
    return (
        <div className='wallpaper flex flex-col w-4.25/5 xl:w-4.5/5 h-full gap-y-2'>
            <Dialog open={modal} classes={{paper: "!max-w-4.25/5 !w-4.25/5 xl:!max-w-3.5/5 xl:!w-3.5/5 2xl:!max-w-3/5 2xl:!w-3/5 4xl:!max-w-2/5 4xl:!w-2/5 !rounded-lg !bg-neutral-400"}}>
                <DialogTitle className='flex justify-between w-full items-center !py-1  '>
                    <div className='flex justify-start w-4.5/5'>
                        <p className='mb-0 font-semibold text-neutral-200'>{t("intercompanySales.criterials.title")}</p>
                    </div>
                    <IconButton color="inherit" onClick={() => setModal(!modal)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className='h-4.5/5'>
                    <ModalRules />
                </DialogContent>
            </Dialog>
            {loading ? (
                <Loading text={loadingText} />
            ): null}
            <div className='flex w-full justify-center items-center h-0.25/5 xl:h-0.50/5 2xl:h-0.25/5 bg-brand-200'>
                <div className='flex justify-center w-4.75/5'>
                    <p className='mb-0 text-xs xl:text-base 2xl:text-xl 4xl:text-2xl font-semibold'>{t("intercompanySales.plantaEntrega")}</p>
                </div>
                <div className='flex justify-center w-0.25/5'>
                    <IconButton color="inherit" onClick={() => setModal(!modal)} aria-label="open">
                        <InfoOutlinedIcon />
                    </IconButton>
                </div>
            </div>
            <div className='flex flex-col h-4.75/5 xl:h-4.5/5 2xl:h-4.75/5 w-full pb-1 px-1 gap-y-2 xl:gap-y-4'>
                <div className='grid grid-cols-12 gap-1 h-0.75/5 2xl:h-0.50/5 overflow-y-auto'>
                    {dataPlants.map((item) => (
                        <button id='plant-ids' key={item.id} onClick={() => selectPlant(item)} disabled={plantSelected.id === item.id} className={`${plantSelected.id === item.id ? 'bg-neutral-300':'bg-neutral-200 hover:bg-neutral-300'} w-full ${buttonsHeight} py-0 2xl:py-0 rounded-md text-xs xl:text-base 4xl:text-xl`}>
                            {item.id}
                        </button>
                    ))}
                </div>
                <div className='grid grid-cols-4 items-center w-full h-0.50/5 4xl:h-0.25/5 bg-neutral-200 rounded-md'>
                    <p className='mb-0 font-semibold text-xs xl:text-base 2xl:text-lg 4xl:text-xl text-center'>{plantSelected.name}</p>
                    <p className='mb-0 font-semibold text-xs xl:text-base 2xl:text-lg 4xl:text-xl text-center'>{t("intercompanySales.fullPotential")}: $ {addThousandPoints(fullPotential)} USD</p>
                    <p className='mb-0 font-semibold text-xs xl:text-base 2xl:text-lg 4xl:text-xl text-center'>{t("intercompanySales.fullPotentialSelected")}: $ {addThousandPoints(fullPotentialSelected)} USD</p>
                    <p className='mb-0 font-semibold text-xs xl:text-base 2xl:text-lg 4xl:text-xl text-center'>{t("intercompanySales.totalSelected")}: $ {addThousandPoints(totalSelected)} USD</p>
                </div>
                <div className='flex w-full h-0.25/5 xl:h-0.50/5 2xl:h-0.25/5 gap-2'>
                    <button onClick={() => setSelected("all")} disabled={selected === "all"} className={`${selected === "all" ? "bg-neutral-300":"bg-neutral-200 hover:bg-neutral-300"} flex justify-center items-center w-1/2 py-2 rounded-md`}>
                        <p className='mb-0 text-sm xl:text-base 4xl:text-xl font-semibold'>{t("intercompanySales.catalogue")}</p>
                    </button>
                    <button onClick={() => setSelected("selected")} disabled={selected === "selected"} className={`${selected === "selected" ? "bg-neutral-300":"bg-neutral-200 hover:bg-neutral-300"} flex justify-center items-center w-1/2 py-2 rounded-md`}>
                        <p className='mb-0 text-sm xl:text-base 4xl:text-xl font-semibold'>{t("intercompanySales.selectedCatalogue")}</p>
                    </button>
                </div>
                <div className='h-3.25/5 xl:h-3/5 2xl:h-1.5/2 4xl:h-2/5 w-full rounded-md overflow-y-auto'>
                    <TableIntercompany selected={selected} 
                        data={selected === "all" ? dataComplete : dataSelected} 
                        sendChecked={sendChecked}
                        sendCheckeds={sendCheckeds}
                    />
                </div>
                <div className='flex justify-end w-full h-0.25/5 gap-2'>
                    <button onClick={handleExportClick} disabled={dataSelected.length < 1} className='flex justify-center items-center w-0.75/5 disabled:border disabled:border-solid disabled:border-black disabled:bg-neutral-300 bg-brand-200 hover:bg-brand-300 py-2 rounded-md'>
                        <p className='mb-0 text-sm xl:text-base 4xl:text-xl'>{t("downloadButton")}</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Intercompany