import { createContext, useState, useMemo } from "react";
import PropTypes from 'prop-types';

export const ZoneContext = createContext()

/**
 * @context
 * @description This context contains the user's countries
*/
const ZoneContextProvider = ({children}) => {

    /**
     * @state
     * @type {array}
     * @default []
     * @description State to save the user's role
    */
    const [zone, setZone] = useState("");
  
    /**
     * @memo
     * @description Create an object that contains the current state of 'role' and a function to 
     * update it ('setRole').
     * This object is passed as a value to the context provider to allow access and modification of the 
     * state of role from child components.
    */
    const data = useMemo(() => ({
        zone,
        setZone
    }), [zone, setZone]);
  
    return (
      <ZoneContext.Provider value={data}>
          {children}
      </ZoneContext.Provider>
    )
}

ZoneContextProvider.propTypes = {
    children: PropTypes.node,
};

export default ZoneContextProvider